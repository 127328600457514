import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'components/atoms';
import { Button, Heading, Box } from 'grommet';

const SearchButtonUI = ({
  onClick,
  disabled,
  title,
  color,
  showLogo,
  ...otherButtonProps
}) => {
  return (
    <Box flex={'shrink'}>
      <Button
        primary
        data-testid={'search-button'}
        color={color}
        disabled={disabled}
        onClick={onClick}
        reverse
        label={
          title ? (
            <Heading
              data-testid={'button-label'}
              margin={'small'}
              color={'white'}
              level={3}
              size={'small'}
            >
              {title}
            </Heading>
          ) : undefined
        }
        icon={showLogo ? <Logo height={40} width={60} /> : null}
        {...otherButtonProps}
      />
    </Box>
  );
};

SearchButtonUI.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

SearchButtonUI.defaultProps = {
  onClick: () => {},
  disabled: false,
  title: '',
  color: 'cta',
  showLogo: true,
};

export default SearchButtonUI;

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Button } from 'grommet'
import MarkdownBox from './MarkdownBox'

const RenderNoteHistory = ({ updatedAt, notes, author, restoreNotes }) => {
  return (
    <Box gap={'small'}>
      <MarkdownBox source={notes} />
      <Box direction={'row-responsive'} justify={'between'}>
        <Text size={'xsmall'} color={'background-back'}>
          {author} | {Date(updatedAt).toLocaleString()}
        </Text>
        <Button label={'Restore'} onClick={() => restoreNotes(notes)} />
      </Box>
    </Box>
  )
}

RenderNoteHistory.propTypes = {
  updatedAt: PropTypes.number.isRequired,
  notes: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  restoreNotes: PropTypes.func.isRequired
}

export default RenderNoteHistory

import { put, cancelled, select, all } from 'redux-saga/effects';
import { allActions } from 'reduxConfig/reducers';
import { apiGet, apiPost } from './fetchWithCancel';
import { selectors } from 'reduxConfig';
import { toast } from 'react-toastify';

export function* getCustomHeader(action) {
  try {
    const { data } = yield apiGet('getCustomHeader');
    yield put(allActions.customHeader.setCustomHeader(data));
    yield put(allActions.customHeader.getCustomHeaderSuccess());
  } catch (err) {
    console.error('fetch custom Header failed: ', err);
    // toast.error('Unable to retrieve flags from server', {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });
  } finally {
    if (yield cancelled()) {
      console.warn('fetch custom header cancelled');
    }
  }
}

export function* updateCustomHeader(action) {
  const customHeader = action.payload;
  console.debug(('trying to update header:', customHeader));
  try {
    const res = yield apiPost('bank_admin/updateCustomHeader', {
      customHeader,
    });
    yield put(allActions.customHeader.editCustomHeaderSuccess());
    toast.success('Custom Header Updated Successfully!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    yield put(allActions.customHeader.getCustomHeader());
  } catch (err) {
    console.error('Flag post failure: ', err);
    toast.error('Something went wrong trying to update the custom header', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } finally {
    if (yield cancelled()) {
      console.warn('Custom Header post cancelled');
    }
  }
}

import { put, cancelled, select } from 'redux-saga/effects';
import { allActions, selectors } from 'reduxConfig';
import { apiGet } from './fetchWithCancel';
import appConfig from '../../config';

export function* fetchZipSearchBankData(action) {
    const searchRadius = yield select(selectors.zipSearch.selectSearchRadius);
    // const zipCode = yield select(selectors.zipSearch.selectSearchText)
    const { lat, lon } = yield select(selectors.zipSearch.selectSearchCoords);

    const searchParams = { lat, lon, searchRadius };

    try {
        const { data } = yield apiGet(
            'zipSearchBankData',
            {
                lat,
                lon,
                searchRadius,
            },
            { timeout: appConfig.timeout }
        );
        yield put(allActions.stateData.addStateData(data));
        yield put(allActions.fullZipSearch.finishZipSearchSuccess());
    } catch (err) {
        console.error(err);
        yield put(allActions.fullZipSearch.finishZipSearchError(err.message));
    } finally {
        if (yield cancelled()) {
        }
    }
}

export function* fetchMdiBankData(action) {
    const searchRadius = yield select(selectors.zipSearch.selectSearchRadius);
    const { lat, lon } = yield select(selectors.zipSearch.selectSearchCoords);

    const searchParams = { lat, lon, searchRadius };

    try {
        // console.debug('searching for: ', { lat, lon, searchRadius });
        const { data } = yield apiGet(
            'mdiSearchBankData',
            {
                lat,
                lon,
                searchRadius,
            },
            { timeout: appConfig.timeout }
        );
        yield put(allActions.stateData.addStateData(data));
        yield put(allActions.fullZipSearch.finishZipSearchSuccess());
    } catch (err) {
        console.error(err);
        yield put(allActions.fullZipSearch.finishZipSearchError(err.message));
    } finally {
        if (yield cancelled()) {
        }
    }
}

export function* fetchTagListData(action) {
    const searchRadius = yield select(selectors.zipSearch.selectSearchRadius);
    const { lat, lon } = yield select(selectors.zipSearch.selectSearchCoords);
    const lastSearchedRadius = yield select(
        selectors.zipSearch.selectLastSearchedRadius
    );
    const { lat: lastLat, lon: lastLon } = yield select(
        selectors.zipSearch.selectLastSearchedCoords
    );
    const lastSearchedTags = yield select(
        selectors.zipSearch.selectLastSearchedTags
    );
    const { flags } = action.payload;

    const latIsSame = lat == lastLat;
    const lonIsSame = lon == lastLon;
    const searchRadiusIsSame = searchRadius == lastSearchedRadius;
    const tagsIsSame = flags == lastSearchedTags;

    // console.debug({
    //     lat,
    //     lastLat,
    //     lon,
    //     lastLon,
    //     searchRadius,
    //     lastSearchedRadius,
    //     flags,
    //     lastSearchedTags,
    // });

    // if (
    //   lat === lastLat &&
    //   lon === lastLon &&
    //   searchRadius === lastSearchedRadius &&
    //   lastSearchedTags == flags
    // ) {
    //   yield put(allActions.fullZipSearch.finishZipSearchSuccess());
    //   return;
    // }
    const searchParams = { lat, lon, searchRadius, flags };
    try {
        const { data } = yield apiGet(
            'taglist/local',
            {
                lat,
                lon,
                searchRadius,
                flags,
            },
            {
                timeout: appConfig.timeout,
            }
        );
        yield put(allActions.stateData.addStateData(data));
        yield put(allActions.fullZipSearch.finishZipSearchSuccess());
    } catch (err) {
        console.error('tag list local error: ', err);
        yield put(allActions.fullZipSearch.finishZipSearchError(err.message));
    } finally {
        if (yield cancelled()) {
            console.warn('tag list search cancelled');
        }
    }
}

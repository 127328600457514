import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

export const LogoDefaultProps = {
  width: 45,
  height: 35
}

export const Logo = ({ width, height, ...rest }) => {
  return (
    <Box
      width={width + 'px'}
      height={height + 'px'}
      align={'end'}
      justify={'end'}
      {...rest}
    >
      <img
        alt={'Better Banking Logo'}
        src={'/tree.svg'}
        width={'100%'}
        height={'100%'}
      />
    </Box>
  )
}

Logo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
}

Logo.defaultProps = LogoDefaultProps

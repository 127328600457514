import { createSelector } from 'reselect';

export const selectSearchText = (state) => state.fullZipSearch.searchText;
export const selectSearchFetching = (state) => state.fullZipSearch.isFetching;
export const selectSearchFetchingMessage = (state) =>
  state.fullZipSearch.fetchingMessage;
export const selectSearchError = (state) => state.fullZipSearch.isError;
export const selectSearchErrorMessage = (state) =>
  state.fullZipSearch.errorMessage;
export const selectSearchRadius = (state) => state.fullZipSearch.searchRadius;
export const selectLastSearchedText = (state) =>
  state.fullZipSearch.lastSearchedText;
export const selectLastSearchedRadius = (state) =>
  state.fullZipSearch.lastSearchedRadius;

export const selectSearchCoords = (state) => state.fullZipSearch.searchCoords;

export const selectSearchSuggestions = (state) =>
  state.fullZipSearch.searchSuggestions;
export const selectLastSearchedCoords = (state) =>
  state.fullZipSearch.lastSearchedCoords;
export const selectLastSearchedTags = (state) =>
  state.fullZipSearch?.lastSearchedTags ?? [];

export const selectSearchCenterCoords = createSelector(
  selectLastSearchedCoords,
  ({ lat, lon }) => [lon, lat]
);

export const selectZipSearchTextValid = createSelector(
  selectSearchText,
  (searchText) => {
    if (!searchText) {
      return false;
    }
    const pattern = /^\d{5}$|^\d{5}-\d{4}$/;
    const isValid = pattern.test(searchText);
    return isValid;
  }
);

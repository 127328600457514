import { put, cancelled, select } from 'redux-saga/effects'
import { allActions } from 'reduxConfig/reducers'
import { apiGet } from './fetchWithCancel'
import { selectors } from 'reduxConfig'

export function * fetchBankDetail (action) {
  const bankRssd = action.payload
  const radius = yield select(selectors.zipSearch.selectSearchRadius)
  const coords = yield select(selectors.zipSearch.selectSearchCenterCoords)
  try {
    const { data } = yield apiGet('bankDetail', { bankRssd, coords, radius })
    yield put(allActions.bankDetail.finishGetBankDetailSuccess(data))
  } catch (err) {
    yield put(allActions.bankDetail.finishGetBankDetailFailure(err.message))
  } finally {
    if (yield cancelled()) {
    }
  }
}

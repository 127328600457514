module.exports = {
    app: {
        title: 'Better Banking',
        description: 'Find a Better Bank',
        head: {
            titleTemplate: 'Better Banking',
            meta: [
                {
                    name: 'description',
                    content: 'Find a Better Bank',
                },
                { charset: 'utf-8' },
            ],
        },
    },
    timeout: 15000,
};

import { put, cancelled, select, all } from 'redux-saga/effects';
import { allActions } from 'reduxConfig/reducers';
import { apiGet, apiPost } from './fetchWithCancel';
import { selectors } from 'reduxConfig';
import { toast } from 'react-toastify';

export function* populateFlags(action) {
  try {
    const token = yield select(selectors.auth.selectToken);
    const { data } = yield apiGet('getFlags');
    // console.debug(data);
    yield put(allActions.bankAdmin.setFlags(data));
  } catch (err) {
    console.error('fetch flags failed: ', err);
    toast.error('Unable to retrieve flags from server', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } finally {
    if (yield cancelled()) {
      console.warn('fetch flags cancelled');
    }
  }
}

export function* deleteFlag(action) {
  const flagName = action.payload;
  try {
    const res = yield apiPost('bank_admin/deleteFlag', { flagName });
    yield put(allActions.bankAdmin.deleteFlagSuccess());
    toast.success('Flag Deleted Successfully.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    yield put(allActions.bankAdmin.getFlags());
  } catch (err) {
    toast.error('Something went wrong deleting this flag', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } finally {
    if (yield cancelled()) {
      console.warn('flag delete cancelled');
    }
  }
}

export function* createFlag(action) {
  const flag = action.payload;
  try {
    const res = yield apiPost('bank_admin/createFlag', { flag });
    yield put(allActions.bankAdmin.createNewFlagSuccess());
    toast.success('Flag Created Successfully!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    yield put(allActions.bankAdmin.getFlags());
  } catch (err) {
    console.error('Flag post failure: ', err);
    toast.error('Something went wrong trying to create this flag', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } finally {
    if (yield cancelled()) {
      console.warn('Flag post cancelled');
    }
  }
}

export function* updateFlag(action) {
  const flag = action.payload;
  try {
    const res = yield apiPost('bank_admin/updateFlag', { flag });
    yield put(allActions.bankAdmin.editFlagSuccess());
    toast.success('Flag Updated Successfully!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    yield put(allActions.bankAdmin.getFlags());
  } catch (err) {
    console.error('Flag post failure: ', err);
    toast.error('Something went wrong trying to update this flag', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } finally {
    if (yield cancelled()) {
      console.warn('Flag post cancelled');
    }
  }
}

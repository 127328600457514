import React from 'react';
import { Box, Text, Button, Anchor } from 'grommet';
import {
    Checkbox,
    CheckboxSelected,
    CircleInformation as Info,
    Notes,
} from 'grommet-icons';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'components/atoms';
import { TagBar } from 'components/molecules';
import { ScoreMeter } from 'app/grommetComponents';
import { selectors, allActions } from 'reduxConfig';
import minBy from 'lodash/minBy';
import { useCompact } from 'app/hooks';

export const NationalBankItem = ({ bank, navToDetails }) => {
    const isCompact = useCompact(['xsmall', 'small']);
    const dispatch = useDispatch();

    const infoIcon = <Info color={'light-3'} size={'1rem'} />;
    const bankHasNotes = (bank?.clientDetails?.notes ?? '').length > 0;

    return (
        <Box
            flex={'grow'}
            fill={'horizontal'}
            key={bank.rssd}
            background={'light-3'}
        >
            <Row
                background={'background-back'}
                border={{
                    side: 'bottom',
                    size: 'small',
                    color: 'brand',
                }}
                pad={'xsmall'}
                align={'center'}
            >
                <Text color={'light-3'} margin={'none'} truncate>
                    {bank?.bankInformation?.name ?? 'BANK NAME'}
                </Text>
                <Row fill={false} gap={'small'} margin={{ right: 'small' }}>
                    <Button plain onClick={navToDetails} icon={infoIcon}></Button>
                </Row>
            </Row>
            <Box
                direction={'row-responsive'}
                fill={'horizontal'}
                pad={{ horizontal: 'small' }}
                background={'light-1'}
            >
                <Box align="center" justify={'center'} flex={'grow'}>
                    <TagBar
                        fill={'horizontal'}
                        justify={'center'}
                        flagArray={bank?.flagArray ?? []}
                        isCompact={true}
                        width={{ max: 'xlarge' }}
                    />
                </Box>
                <Box align={'center'} justify={'center'} flex={'grow'}>
                    <ScoreMeter bank={bank} />
                    <Anchor
                        as={'a'}
                        onClick={navToDetails}
                        color={'dark-4'}
                        size={'xsmall'}
                    >
                        View Details
                    </Anchor>
                </Box>
            </Box>
        </Box>
    );
};

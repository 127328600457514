import React from 'react'
import { Box, Text } from 'grommet'
import { LinkButton } from 'components/atoms'
import PropTypes from 'prop-types'
import { useLocation } from 'react-use'

export const CustomLinkButton = ({ path, label, color, size }) => {
  return (
    <LinkButton fill plain to={path}>
      <Box fill justify={'end'} align={'center'}>
        <Text textAlign={'center'} weight={'bold'} color={color} size={size}>
          {label}
        </Text>
      </Box>
    </LinkButton>
  )
}

CustomLinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
}

export const CustomLinkAnchor = ({ path, label, color, size }) => {
  return (
    <Box fill justify={'end'} align={'center'}>
      <Text
        as={'a'}
        href={path}
        rel='noopener noreferrer'
        target={'_blank'}
        textAlign={'center'}
        weight={'bold'}
        color={color}
        size={size}
      >
        {label}
      </Text>
    </Box>
  )
}

CustomLinkAnchor.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
}

export const AppBarLink = ({
  name,
  label,
  sideLabel,
  path,
  useAnchor,
  inAppBar
}) => {
  const location = useLocation()
  const labelToDisplay = inAppBar ? label : sideLabel
  const textColor = location.pathname.startsWith(path) ? 'white' : 'neutral-1'
  const textSize = inAppBar ? 'medium' : 'large'
  const computedProps = {
    label: labelToDisplay,
    color: textColor,
    size: textSize,
    path
  }
  const linkWidth = inAppBar ? '100px' : undefined
  return (
    <Box width={linkWidth}>
      {useAnchor ? (
        <CustomLinkAnchor {...computedProps} />
      ) : (
        <CustomLinkButton {...computedProps} />
      )}
    </Box>
  )
}

AppBarLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sideLabel: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  useAnchor: PropTypes.bool.isRequired,
  inAppBar: PropTypes.bool.isRequired
}

AppBarLink.defaultProps = {
  useAnchor: false,
  inAppBar: true
}

import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { Box, Heading, Text } from 'grommet'

const MarkdownBox = ({ source }) => {
  return (
    <Box gap={'small'} background={'white'} flex width={{ min: 'small' }}>
      <Box
        flex
        round={'xsmall'}
        pad={'small'}
        border={{ size: 'small', color: 'brand' }}
        overflow={'hidden'}
      >
        <Text>
          <ReactMarkdown source={source} />
        </Text>
      </Box>
    </Box>
  )
}

MarkdownBox.propTypes = {
  source: PropTypes.string.isRequired
}

export default MarkdownBox

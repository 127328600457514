const totalAssetsEditable = {
  label: 'Asset Size',
  headerText: 'Asset Size',
  hookText: 'Small Enough to Serve',
  blurbText:
    'No Bank should be Too Big to Fail. The 10 largest banking institutions in the United States hold roughly 50% of all banking assets in the US and have names you would recognize. We use this score to bring smaller, less well known banks to your attention.',
  definition: [
    `Let's be honest. To move one checking account is not going to hurt a mega-bank. But it IS going to help a local community bank that will put your deposits to work via loans to area small businesses and mortgages for your neighbors.`,
    `This score is the output of a non-linear formula that turns a bank's total assets into a score between 0 and 1. The smaller a bank is, the closer to 1 it gets. The formula is non-linear because the smallest bank in the US weighs in at around $60,000 in assets, whereas the largest weighs in at over $2 trillion in assets.`,
    'There are many reasons to keep your money away from mega-banks, but a bank can also be too small. For example, an institution can have too few assets to make the mortgage and small business loans that fuel personal and community wealth. In between too small and too-big-to-fail lie thousands of banks doing good work in their communities.'
  ]
}

const totalAssetsNotEditable = {
  name: 'totalAssets',
  to: '/methodology/asset-size',
  iconSrc: '/totalAssets.svg',
  link: 'asset-size',
  weight: 0.1,
  color: 'totalAssets',
  weights: [
    { name: 'qualityLending', label: 'Quality Lending', weight: 0.6 },
    { name: 'qctPresence', label: 'Qualified Tract Presence', weight: 0.2 },
    { name: 'branchDensity', label: 'Branch Density', weight: 0.1 },
    { name: 'totalAssets', label: 'Asset Size', weight: 0.1 }
  ]
}

export const totalAssets = {
  ...totalAssetsEditable,
  ...totalAssetsNotEditable
}

import React, { useRef } from 'react';
import { Box } from 'grommet';
import { BankFlagTag } from 'components/atoms';
import PropTypes from 'prop-types';
import { getFlags } from './getFlags';

export const TagBar = ({ flagArray, showFull, size, ...rest }) => {
  return flagArray.length > 0 ? (
    <Box
      fill={'horizontal'}
      direction={'row'}
      wrap
      gap={'xsmall'}
      pad={'xsmall'}
      justify={'start'}
      width={{ max: 'small' }}
      {...rest}
    >
      {flagArray.map(({ name, shortLabel, fullLabel, description }) => (
        <BankFlagTag
          key={name}
          showFull={showFull}
          short={shortLabel}
          full={fullLabel}
          description={description}
          size={size}
        />
      ))}
    </Box>
  ) : null;
};

TagBar.propTypes = {
  flagArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      shortLabel: PropTypes.string.isRequired,
      fullLabel: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  showFull: PropTypes.bool.isRequired,
};

TagBar.defaultProps = {
  showFull: false,
  flagArray: [],
};

import React from 'react';
import { useCompact } from 'app/hooks';
import { Box, Stack } from 'grommet';
import { useToggle, useMeasure } from 'react-use';
import {
  DeckMap,
  MapSidePanel,
  MapSidePanelButton,
} from 'app/grommetComponents';
import styled from 'styled-components';

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const MapPage = () => {
  const isCompact = useCompact(['xsmall', 'small']);
  const [banksVisible, toggleBanksVisible] = useToggle(true);
  const [ref, { width, height }] = useMeasure();

  return (
    <Box fill={'vertical'} background={'red'} ref={ref}>
      <Stack fill anchor={isCompact ? 'bottom' : 'left'}>
        <Box fill background={'dark-3'}>
          <DeckMap />
        </Box>
        <Box direction={isCompact ? 'column' : 'row-reverse'}>
          <MapSidePanelButton
            isCompact={isCompact}
            onClick={toggleBanksVisible}
            justify={'center'}
            align={'center'}
            flex={'shrink'}
            banksVisible={banksVisible}
          />
          <MapSidePanel
            open={banksVisible}
            isCompact={isCompact}
            parentHeight={height}
            parentWidth={width}
          />
        </Box>
      </Stack>
    </Box>
  );
};

import { put, cancelled, select } from 'redux-saga/effects'
import { allActions } from 'reduxConfig/reducers'
import { apiGet, apiPost } from './fetchWithCancel'
import { selectors } from 'reduxConfig'
import { toast } from 'react-toastify'

export function * bankAdminFetch (action) {
  const { rssd } = action.payload
  yield put(allActions.bankAdmin.getFlags())
  try {
    const {
      data: { institution, flags }
    } = yield apiGet('bank_admin/get', {
      rssd
    })
    yield put(allActions.bankAdmin.finishAdminFetchSuccess(institution))

    const defaultClientFlags = flags.reduce((acc, flag) => {
      acc[flag.name] = (institution?.flagArray ?? []).includes(flag.name)
      return acc
    }, {})

    const newDetails = {
      notes: institution?.clientDetails?.notes ?? '',
      flags: defaultClientFlags,
      name: institution?.name ?? ''
    }
    yield put(allActions.bankAdmin.hydrateClientDetails(newDetails))
  } catch (err) {
    yield put(allActions.bankAdmin.finishAdminFetchError(err.message))
  } finally {
    if (yield cancelled()) {
      console.warn('get cancelled')
    }
  }
}

const defaultDetails = {
  name: '',
  notes: '',
  flags: {}
}

export function * bankAdminWrite (action) {
  const { user } = action.payload
  const { rssd } = yield select(selectors.bankAdmin.selectInstitution)
  const newClientData = yield select(selectors.bankAdmin.selectClientDetails)
  try {
    const {
      data: { institution }
    } = yield apiPost('bank_admin/set', {
      rssd,
      user,
      newDocument: newClientData
    })
    toast.success('Institution Updated Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    })
    yield put(
      allActions.bankAdmin.finishAdminWriteSuccess({
        message: 'Institution Updated Successfully',
        institution
      })
    )
  } catch (err) {
    yield put(allActions.bankAdmin.finishAdminWriteError(err.message))
  } finally {
    if (yield cancelled()) {
      console.warn('set cancelled')
    }
  }
}

import React from 'react'
import { Box, Text } from 'grommet'
import { SplashSearch } from 'app/grommetComponents'
import { useSelector } from 'react-redux'
import { selectors } from 'reduxConfig'

export const ZipSearchError = ({ showSearchOnEmpty = true }) => {
  const errorMessage = useSelector(selectors.zipSearch.selectSearchErrorMessage)
  return (
    <Box fill justify={'center'} align={'center'}>
      <Text textAlign={'center'} size={'large'} color={'status-error'}>
        {errorMessage}
      </Text>
      <Box margin={'large'} align={'center'} justify={'center'}>
        {showSearchOnEmpty && <SplashSearch />}
      </Box>
    </Box>
  )
}

import { createSelector } from 'reselect'

// Core fields in stateData reducer

export const selectSelectedRssd = state => state.stateData.selectedRssd // int
export const selectAllBanks = state => state.stateData.allBanks // obj
export const selectAllBranches = state => state.stateData.allBranches // obj
export const selectOrderedBankIds = state => state.stateData.orderedBankIds // arr sorted
export const selectBankIdsByFlag = state => state.stateData.bankIdsByFlag
export const selectFetching = state => state.stateData.fetching // bool
export const selectError = state => state.stateData.error // str
export const selectVisibleRssds = state => state.stateData.visibleRssds // arr
export const selectSearchCenter = state => state.stateData.searchCenter // arr coords [lon, lat]
export const selectTopBankCount = state => state.stateData.topBankCount // int
export const selectAllBranchIds = state => state.stateData.allBranchIds // arr

export const selectReturnedBankCount = createSelector(
  selectOrderedBankIds,
  ids => ids.length
)

// Sorted id lists for filters

export const selectIds_mcu = state => state.stateData.bankIdsByFlag.mcu // arr sorted
export const selectIds_licu = state => state.stateData.bankIdsByFlag.licu // arr sorted
export const selectIds_cdfi = state => state.stateData.bankIdsByFlag.cdfi // arr sorted
export const selectIds_communityBank = state =>
  state.stateData.bankIdsByFlag.communityBank
export const selectIds_mdi = state => state.stateData.bankIdsByFlag.mdi
export const selectIds_fdic = state => state.stateData.bankIdsByRegulator.fdic
export const selectIds_ncua = state => state.stateData.bankIdsByRegulator.ncua

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  loading: false,
  loadingMessage: '',
  error: false,
  errorMessage: '',
  institution: undefined,
  successMessage: '',
  allFlags: [],
  clientDetails: {
    notes: '',
    flags: {},
    name: ''
  }
}

const bankAdminSlice = createSlice({
  name: 'bankAdmin',
  initialState,
  reducers: {
    hydrateClientDetails (state, action) {
      state.clientDetails = action.payload
    },
    updateClientDetails (state, action) {
      const { field, value } = action.payload
      state.clientDetails[field] = value
    },
    getFlags (state, action) {
      // action used to kick off saga
      return state
    },
    createNewFlag (state, action) {
      // action used to kick off saga
      return state
    },
    updateFlag (state, action) {
      // action used to kick off saga
      return state
    },
    deleteFlag (state, action) {
      // action used to start delete saga
      return state
    },
    createNewFlagSuccess (state, action) {
      state.successMessage = 'Flag Created Successfully.'
    },
    editFlagSuccess (state, action) {
      state.successMessage = 'Flag Edited Successfully.'
    },
    deleteFlagSuccess (state, action) {
      state.successMessage = 'Flag Deleted Successfully.'
    },
    setFlags (state, action) {
      state.allFlags = action.payload
    },
    startAdminFetch (state, action) {
      state.loading = true
      state.loadingMessage = action.payload
      state.error = false
      state.errorMessage = ''
      state.successMessage = ''
      state.institution = undefined
    },
    finishAdminFetchSuccess (state, action) {
      state.loading = false
      state.loadingMessage = ''
      state.error = false
      state.errorMessage = ''
      state.successMessage = ''
      state.institution = action.payload
    },
    finishAdminFetchError (state, action) {
      state.loading = false
      state.loadingMessage = ''
      state.error = true
      state.errorMessage = action.payload
      state.successMessage = ''
      state.institution = undefined
    },
    startAdminWrite (state, action) {
      const { loadingMessage } = action.payload
      state.loading = true
      state.loadingMessage = loadingMessage
      state.error = false
      state.errorMessage = ''
      state.successMessage = ''
    },
    finishAdminWriteSuccess (state, action) {
      const { message, institution } = action.payload
      state.loading = false
      state.loadingMessage = ''
      state.error = false
      state.errorMessage = ''
      state.successMessage = message
      state.institution = institution
      state.clientDetails = institution?.clientDetails ?? {}
    },
    finishAdminWriteError (state, action) {
      state.loading = false
      state.loadingMessage = ''
      state.error = true
      state.errorMessage = action.payload
      state.successMessage = ''
    }
  }
})

export const {
  startAdminFetch,
  finishAdminFetchError,
  finishAdminFetchSuccess,
  startAdminWrite,
  finishAdminWriteError,
  finishAdminWriteSuccess,
  hydrateClientDetails,
  updateClientDetails,
  setFlags,
  getFlags,
  createNewFlag,
  createNewFlagSuccess,
  updateFlag,
  editFlagSuccess,
  deleteFlag,
  deleteFlagSuccess
} = bankAdminSlice.actions

export default bankAdminSlice.reducer

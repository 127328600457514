import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { WebMercatorViewport } from 'deck.gl'
import MapGL, { NavigationControl } from 'react-map-gl'
import { DeckGL } from '@deck.gl/react'
import bbox from '@turf/bbox'
import circle from '@turf/circle'
import chunk from 'lodash/chunk'
import { selectors, allActions } from 'reduxConfig'

// local helpers
import { generateDeckLayers } from './helpers'
import { NavigationContainer } from './NavigationContainer'

const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

const initialViewState = {
  altitude: 1.5,
  bearing: 0,
  height: 714,
  latitude: 44.456840987671136,
  longitude: -95.11530521567683,
  maxPitch: 60,
  maxZoom: 18,
  minPitch: 0,
  minZoom: 0,
  pitch: 0,
  width: 660,
  zoom: 2.4870050492929443
}

export const DeckMap = () => {
  // selectors for redux data
  const filteredBranches = useSelector(selectors.map.selectMapBranchData)
  const searchRadius = useSelector(selectors.zipSearch.selectLastSearchedRadius)
  const searchCenter = useSelector(selectors.zipSearch.selectSearchCenterCoords)
  const selectedRssd = useSelector(selectors.stateData.selectSelectedRssd)

  // hooks for redux integration
  const dispatch = useDispatch()

  // local state for handling view state & loading
  const [viewState, setViewState] = useState(initialViewState)

  // refs
  const mapRef = useRef(null)
  const viewStateRef = useRef(viewState)

  // move map any time searchRadius, searchCenter changes
  useEffect(() => {
    if (searchRadius > 0 && searchCenter.length === 2) {
      const circlePoly = circle(searchCenter, searchRadius, {
        units: 'miles',
        steps: 10
      })
      const circleBbox = bbox(circlePoly)
      const circleBounds = chunk(circleBbox, 2)
      const { zoom, latitude, longitude } = new WebMercatorViewport(
        viewStateRef.current
      ).fitBounds(circleBounds, { padding: 10 })

      const newViewState = {
        ...viewStateRef.current,
        latitude,
        longitude,
        zoom,
        transitionDuration: 750
      }
      delete newViewState["default-view"];
      viewStateRef.current = newViewState
      setViewState(newViewState)
    }
  }, [searchRadius, searchCenter, viewStateRef, setViewState])

  const handleBankClick = rssd => {
    dispatch(allActions.stateData.setSelectedRssd(rssd))
  }

  const layers = generateDeckLayers({
    branchData: filteredBranches.reverse(),
    viewState,
    handleBankClick,
    searchRadius,
    searchCenter,
    selectedRssd
  })

  const handleViewStateChange = ({
    viewState: newViewState,
    interactionState,
    oldViewState
  }) => {
    setViewState(newViewState)
  }

  return (
    <DeckGL
      ref={mapRef}
      viewState={viewState}
      onViewStateChange={handleViewStateChange}
      initialViewState={initialViewState}
      controller
      layers={layers}
    >
      <MapGL
        mapStyle={'mapbox://styles/mapbox/light-v8'}
        reuseMap={false}
        mapboxApiAccessToken={REACT_APP_MAPBOX_TOKEN}
      >
        <NavigationContainer>
          <NavigationControl onViewStateChange={handleViewStateChange} />
        </NavigationContainer>
      </MapGL>
    </DeckGL>
  )
}

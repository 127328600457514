import React from 'react'
import { Box } from 'grommet'

const Row = props => {
  return (
    <Box
      direction={'row'}
      align={'center'}
      fill={'horizontal'}
      justify={'between'}
      {...props}
    />
  )
}

export default Row

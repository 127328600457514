const andyName = 'Andy Loving'

const andyImage = '/Andy-square.png'

const andyText = `### Andy Loving

Andy Loving is the founding partner of Just Money Advisors and its principle advisor. A Certified Financial Planner® and an ordained minister, Andy creates investment strategies that help clients meet their financial goals in a way that proactively contributes to justice and environmental sustainability. He has won multiple awards for helping clients put capital into financial institutions that serve poor people, both domestically and around the world. It has been observed that “Andy looks at money and imagines ways to create justice.”

[www.justmoneyadvisors.com](https://www.justmoneyadvisors.com)
`

export const andyContent = {
  name: andyName,
  image: andyImage,
  text: andyText
}

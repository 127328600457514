import React from 'react';
import { Box, Text, Heading } from 'grommet';

export const DataUpdatedSection = () => {
    return (
        <Box fill={'horizontal'} justify={'center'} flex={false}>
            <Box
                flex={false}
                align={'center'}
                alignSelf={'center'}
                pad={'medium'}
                width={{ max: 'xlarge' }}
            >
                <Box gap={'small'} flex={false}>
                    <Heading level={2} textAlign={'center'}>
                        New banking data from the NCUA and FDIC has been added to the search engine.
                    </Heading>
                </Box>
                <Box gap={'small'} flex={false}>
                    <Text textAlign={'center'}>
                        This data is from the 2nd quarter of 2023 and was updated as of June 13, 2024.
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

DataUpdatedSection.propTypes = {};

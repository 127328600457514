import React from 'react'
import PropTypes from 'prop-types'
import { Form, Box, FormField, Button, TextInput } from 'grommet'

export const RssdSearch = ({ startSearch }) => {
  return (
    <Form onSubmit={startSearch}>
      <Box
        justify={'center'}
        gap={'small'}
        align={'center'}
        direction={'row-responsive'}
      >
        <FormField
          component={TextInput}
          id={'rssd'}
          placeholder={'Search by RSSD'}
          name={'rssd'}
          type={'number'}
        />
        <Button type={'submit'} label={'Search'} primary />
      </Box>
    </Form>
  )
}

RssdSearch.propTypes = {
  startSearch: PropTypes.func.isRequired
}

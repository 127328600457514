import React, { useState } from 'react'
import { Box, Meter, Stack, Text } from 'grommet'
import round from 'lodash/round'
import { useCompact } from 'app/hooks'

export const ScoreMeter = ({ bank, ...rest }) => {
  const isCompact = useCompact(['xsmall', 'small'])
  const { score, scoresArray } = bank
  const [active, setActive] = useState()
  const [label, setLabel] = useState()
  const [dropColor, setDropColor] = useState('black')

  const meterValues = scoresArray.map(
    ({ name, label, weight, rawScore, weightedScore }, idx) => {
      const roundedScore = round(weightedScore * 100, 2)

      return {
        color: name,
        value: roundedScore,
        onHover: isOver => {
          setActive(isOver ? roundedScore : 0)
          setLabel(isOver ? label : undefined)
          setDropColor(isOver ? name : undefined)
        }
      }
    }
  )

  meterValues.unshift({ color: 'light-4', value: ((1 - score) / 2) * 100 })

  return (
    <Box pad={{ top: 'small' }} justify={'center'} align={'center'}>
      <Stack anchor={'top'}>
        <Stack anchor='center'>
          <Meter
            type='circle'
            background='light-4'
            values={meterValues}
            max={100}
            size={isCompact ? '70px' : '95px'}
            thickness={isCompact ? 'xsmall' : 'small'}
            {...rest}
          />
          <Box align={'center'} justify={'center'}>
            <Box direction={'row'} align='center' justify={'center'}>
              <Text
                size={isCompact ? 'small' : 'medium'}
                weight='bold'
                textAlign={'center'}
                color={dropColor || 'black'}
              >
                {active || round(score * 100, 2)}
              </Text>
              <Text size={'small'}>%</Text>
            </Box>
          </Box>
        </Stack>
        {!!label && (
          <Box
            justify={'center'}
            align={'center'}
            width={'180px'}
            pad={{ horizontal: 'small', vertical: 'xxsmall' }}
            background={'light-2'}
            round={'xsmall'}
            margin={'xxsmall'}
            elevation={'small'}
          >
            <Text textAlign={'center'} color={dropColor} size={'small'}>
              {label}
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from 'grommet';
import {
    SearchButton,
    SearchInput,
    SearchRangeInput,
    SearchGeoLocationButton,
} from 'app/grommetComponents';
import { useGeolocation } from 'react-use';
import { selectors } from 'reduxConfig';
import appConfig from '../../../config';
import { NewSearchComponent } from 'app/pages/HomePage/NewSplash';

const SearchRadiusText = () => {
    const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
    return (
        <Box flex={'grow'} pad={'small'}>
            <Text
                color={'background-back'}
                weight={'bold'}
                textAlign={'center'}
                // size={'large'}
            >
            {searchRadius} mile
            {Math.abs(searchRadius) !== 1 ? 's' : ''}
            </Text>
            <Text textAlign={'center'} color={'dark-3'} size={'xxsmall'}>
                SEARCH RADIUS
            </Text>
        </Box>
    );
};

const FormattedSearchInput = () => {
    const { error, latitude, longitude, loading } = useGeolocation({
        maximumAge: 10 * 60 * 1000,
        timeout: appConfig.timeout,
        enableHighAccuracy: false,
    });
    return (
        <Box>
            <Box
                align={'center'}
                justify={'center'}
                gap={'small'}
                pad={'small'}
                direction={'row'}
                fill
                // width={{ min: 'medium' }}
            >
            <Box flex={'grow'} fill={'horizontal'} justify={'center'}>
                <SearchInput />
            </Box>
            <Box flex={'shrink'}>
                <SearchGeoLocationButton />
            </Box>
            </Box>
            {error && (
                <Text textAlign={'center'} size="xsmall">
                    Geolocation disabled
                </Text>
            )}
        </Box>
    );
};

const FormattedSearchRange = () => {
    return (
        <Box
            flex={false}
            direction={'row'}
            justify={'center'}
            align={'center'}
            gap={'small'}
            pad={'small'}
        >
            <SearchRadiusText />
            {/* <Box width={{ min: 'small' }}> */}
            <SearchRangeInput color={'background-back'} style={{ color: 'cta' }} />
            {/* </Box> */}
        </Box>
    );
};

export const HorizSearch = {};

// legacy version, doesn't work on public site
export const SplashSearch = ({
    isCompact,
    direction = 'column',
    hideSearch,
    onPress,
    title = 'Find Better Banks',
}) => {
    return (
        <Box
            flex={false}
            gap={'small'}
            // width={{ max: 'large' }}
            align={'center'}
            direction={direction}
            justify={'evenly'}
        >
        <FormattedSearchInput />

        <FormattedSearchRange />
        <Box pad={'small'}>
            <SearchButton
                beforeSearch={hideSearch}
                onPress={onPress}
                color={'accent-3'}
                title={title}
            />
        </Box>
        </Box>
    );
};

// new "drop-in" version, does work on public site
export const SplashSearch2 = ({
    isCompact,
    direction = 'column',
    hideSearch = () => {},
    onPress = () => {},
    title = 'Find Better Banks',
}) => {
    return (
        <NewSearchComponent
            flex={false}
            gap={'small'}
            align={'center'}
            direction={direction}
            justify={'evenly'}
            beforeSearch={hideSearch}
            onPress={onPress}
            title={title}
            width={null}
        />
    );
};

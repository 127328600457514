import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Layer,
  Form,
  FormField,
  TextInput,
  Button,
  TextArea,
  Text,
  Select
} from 'grommet'
import { selectors, allActions } from 'reduxConfig'
import { useSelector, useDispatch } from 'react-redux'
import { Spinning } from 'grommet-controls'
import { useToggle } from 'react-use'

export const AddNewFlagForm = ({
  allFlags,
  visible,
  submitForm,
  toggleForm
}) => {
  const dispatch = useDispatch()
  const flagNames = allFlags.map(f => f.name)

  const newFlagForm = [
    {
      name: 'name',
      label: 'Flag Name',
      help: 'Lower Case Only',
      validate: (str, frm) => {
        if (flagNames.includes(str)) {
          return `Name ID ${str} already exists`
        }
        return undefined
      }
    },
    {
      name: 'shortLabel',
      help: 'Will show in the flag list',
      label: 'Short Label'
    },
    {
      name: 'fullLabel',
      help: 'Will show when flag is hovered',
      label: 'Full Label'
    },
    {
      name: 'description',
      label: 'Flag Description'
    }
  ]

  const formButtons = [
    {
      label: 'Cancel',
      onClick: toggleForm
    },
    {
      label: 'Reset',
      type: 'reset'
    },
    {
      label: 'Create Flag',
      type: 'submit'
    }
  ]

  return (
    <React.Fragment>
      {visible && (
        <Layer plain onEsc={toggleForm} modal>
          <Box
            elevation={'medium'}
            round={'small'}
            pad={'medium'}
            background={'neutral-3'}
          >
            <Text color={'light-1'}>
              <Form onSubmit={submitForm}>
                {newFlagForm.map(formFieldProps => {
                  return (
                    <FormField
                      key={formFieldProps.name}
                      required
                      {...formFieldProps}
                    />
                  )
                })}
                <Box direction={'row'} gap={'small'} pad={'small'}>
                  {formButtons.map(buttonProps => {
                    return (
                      <Button
                        key={buttonProps.label}
                        style={{ background: 'white' }}
                        color={'accent-2'}
                        {...buttonProps}
                      />
                    )
                  })}
                </Box>
              </Form>
            </Text>
          </Box>
        </Layer>
      )}
    </React.Fragment>
  )
}

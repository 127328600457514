import React from 'react'
import { Box } from 'grommet'
import { BankDetailComponent } from 'app/grommetComponents'

export const DetailPage = () => {
  return (
    <Box fill>
      <BankDetailComponent />
    </Box>
  )
}

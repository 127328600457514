import React from 'react'
import { Box } from 'grommet'
import { SplashSearch2 } from 'app/grommetComponents'

export const SearchPage = () => {
  return (
    <Box fill align={'center'} justify={'center'}>
      <SplashSearch2 isCompact={false} />
    </Box>
  )
}

import { fork, takeLatest, all, call } from 'redux-saga/effects';
import { allActions as actions } from '../reducers';
import {
  fetchZipSearchBankData,
  fetchMdiBankData,
  fetchTagListData,
} from './fetchZipSearchBankData';
import { fetchBankDetail } from './fetchBankDetailData';
import { fetchSearchSuggestions } from './fetchSearchSuggestions';
import { bankAdminFetch, bankAdminWrite } from './bankAdmin';
import {
  populateFlags,
  createFlag,
  updateFlag,
  deleteFlag,
} from './fetchFlags';
import { getCustomHeader, updateCustomHeader } from './customHeader.sagas';

function* watchBankAdminFetch() {
  yield takeLatest(actions.bankAdmin.startAdminFetch, bankAdminFetch);
}

function* watchGetFlags() {
  yield takeLatest(actions.bankAdmin.getFlags, populateFlags);
}

function* watchCreateFlags() {
  yield takeLatest(actions.bankAdmin.createNewFlag, createFlag);
}

function* watchUpdateFlags() {
  yield takeLatest(actions.bankAdmin.updateFlag, updateFlag);
}

function* watchDeleteFlag() {
  yield takeLatest(actions.bankAdmin.deleteFlag, deleteFlag);
}

function* watchBankAdminWrite() {
  yield takeLatest(actions.bankAdmin.startAdminWrite, bankAdminWrite);
}

function* watchZipSearch() {
  yield takeLatest(
    actions.fullZipSearch.startZipSearch,
    fetchZipSearchBankData
  );
}

function* watchMdiSearch() {
  yield takeLatest(actions.fullZipSearch.startMdiSearch, fetchMdiBankData);
}

function* watchTagSearch() {
  yield takeLatest(actions.fullZipSearch.startTagSearch, fetchTagListData);
}

function* watchGetBankDetail() {
  yield takeLatest(actions.bankDetail.startGetBankDetail, fetchBankDetail);
}

function* watchSearchText() {
  yield takeLatest(
    actions.fullZipSearch.updateSearchText,
    fetchSearchSuggestions
  );
}

function* watchGetCustomHeader() {
  yield takeLatest(actions.customHeader.getCustomHeader, getCustomHeader);
}

function* watchUpdateCustomHeader() {
  yield takeLatest(actions.customHeader.updateCustomHeader, updateCustomHeader);
}

export default function* rootSaga() {
  yield fork(watchZipSearch);
  yield fork(watchGetBankDetail);
  yield fork(watchSearchText);
  yield fork(watchBankAdminFetch);
  yield fork(watchBankAdminWrite);
  yield fork(watchGetFlags);
  yield fork(watchCreateFlags);
  yield fork(watchUpdateFlags);
  yield fork(watchDeleteFlag);
  // gets custom header data from mongo
  yield fork(watchGetCustomHeader);
  yield fork(watchUpdateCustomHeader);
  // kicks off mdi branch search
  yield fork(watchMdiSearch);
  yield fork(watchTagSearch);
}

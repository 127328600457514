import React from 'react'
import { Box, Text } from 'grommet'
import { SplashSearch } from 'app/grommetComponents'

export const ZipSearchNoResults = ({ showSearchOnEmpty = true }) => {
  return (
    <Box
      pad={'small'}
      gap={'small'}
      fill={'vertical'}
      align={'center'}
      justify={'center'}
    >
      <Text textAlign={'center'}>
        No results found. Please try either expanding the search radius, or
        changing the zip code.
      </Text>

      {showSearchOnEmpty && <SplashSearch />}
    </Box>
  )
}

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const customizedMiddleware =
  process.env.NODE_ENV === 'production'
    ? [...getDefaultMiddleware({ thunk: false, logger: false }), sagaMiddleware]
    : [
        ...getDefaultMiddleware({ thunk: false, logger: false }),
        sagaMiddleware,
        createLogger({
          collapsed: (getState, action) => true
        })
      ]

export default () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware
    // devTools: process.env.NODE_ENV !== 'production'
  })

  sagaMiddleware.run(rootSaga)

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
      const newRootReducer = require('./reducers').default
      store.replaceReducer(newRootReducer)
    })
  }

  return store
}

import React, { useState } from 'react';
import { Box, Text, Button } from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { SubtractCircle, AddCircle } from 'grommet-icons';
import { useToggle } from 'react-use';
import { allActions, selectors } from 'reduxConfig';
import { ZipSearchBankItem } from 'app/grommetComponents';
import { DetailPopover } from './DetailPopover';
import { minBy } from 'ramda';

export const ZipSearchResults = ({ sortByDistance = false }) => {
  // hooks
  const dispatch = useDispatch();

  // redux selectors
  const topBankCount = useSelector(selectors.stateData.selectTopBankCount);
  const vizCount = useSelector(selectors.map.selectFilteredRssdCount);
  const maxCount = useSelector(selectors.map.selectMaxFilteredCount);
  const selectedBankRssd = useSelector(selectors.stateData.selectSelectedRssd);
  const topBanksArray = useSelector(selectors.map.selectSearchResultsBankData);
  const closestByBranchArray = useSelector(
    selectors.map.selectSearchResultsBankDataByMinDistance
  );

  // local state
  const [detailPopoverVisible, toggleDetailPopover] = useToggle(false);
  const [bankDetails, setBankDetails] = useState({ rssd: '', bankName: '' });

  // handlers
  const navToDetail = ({ rssd, bankName }) => () => {
    setBankDetails({ rssd, bankName });
    toggleDetailPopover();
  };

  const showMore = () => dispatch(allActions.stateData.incrementTopBankCount());
  const showLess = () => dispatch(allActions.stateData.decrementTopBankCount());

  // console.debug('*** I AM RENDERED ***');

  return (
    <Box fill>
      <Box
        fill
        overflow={'auto'}
        justify={'start'}
        align={'stretch'}
        background={'white'}
        gap={'xsmall'}
      >
        {(sortByDistance ? closestByBranchArray : topBanksArray).map(
          (bank, idx) => {
            return (
              <ZipSearchBankItem
                bank={bank}
                index={idx}
                key={bank.rssd}
                showSelect={true}
                selected={selectedBankRssd === bank.rssd}
                navToDetails={navToDetail({
                  rssd: bank.rssd,
                  bankName: bank.name,
                })}
              />
            );
          }
        )}
      </Box>
      <Box
        fill={'horizontal'}
        align={'center'}
        flex={false}
        background={'light-3'}
        elevation={'medium'}
      >
        <Box
          pad={{ bottom: 'medium' }}
          justify={'around'}
          alignSelf={'center'}
          width={{ max: 'xxlarge' }}
          flex={false}
        >
          <Box
            justify={'center'}
            gap={'xsmall'}
            align={'center'}
            direction={'row'}
            fill={'horizontal'}
          >
            <Button
              icon={<SubtractCircle color={'brand'} />}
              plain
              disabled={topBankCount <= 5}
              onClick={showLess}
            />

            <Box pad={'small'} width={{ min: 'xsmall' }}>
              <Text textAlign={'center'} size={'xsmall'} numberOfLines={1}>
                {vizCount < 10 ? ' ' : ''}
                {Math.min(maxCount, vizCount).toString().padStart(3)} /{' '}
                {maxCount.toString().padEnd(3)}
              </Text>
            </Box>
            <Button
              icon={<AddCircle color={'brand'} />}
              plain
              onClick={showMore}
            />
          </Box>
        </Box>
      </Box>
      <DetailPopover
        open={detailPopoverVisible}
        toggle={toggleDetailPopover}
        {...bankDetails}
      />
    </Box>
  );
};

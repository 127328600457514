import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectors, allActions } from 'reduxConfig';
import { Box, Form, FormField, Button, Text } from 'grommet';
import { Logout } from 'grommet-icons';

export const LogoutButton = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(allActions.auth.logout());
    };

    return (
        <Button onClick={logout} primary>
            <Box
                flex={'shrink'}
                pad={'small'}
                gap={'small'}
                direction={'row'}
                align={'center'}
            >
                <Text color={'light-2'}>Logout</Text>
                <Logout />
            </Box>
        </Button>
    );
}

LogoutButton.propTypes = {};

import React from 'react';
import {
  Box,
  Button,
  Text,
  Collapsible,
  Accordion,
  AccordionPanel,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import { useSelector, useDispatch } from 'react-redux';
import { allActions } from 'reduxConfig';
import { selectors } from 'reduxConfig';
import {
  SplashSearch,
  SearchResultComponent,
  InstitutionFilters,
} from 'app/grommetComponents';
import { useRouter } from 'app/hooks';
import { NewSearchComponent } from 'app/pages/HomePage/NewSplash';

const defaultFilters = {
  ncua: true,
  fdic: true,
  cdfi_fdic: false,
  cdfi_ncua: false,
  communityBank: false,
  licu: false,
  mcu: false,
  mdi: false,
  other_fdic: false,
  other_ncua: false,
};

const acceptableFlags = Object.keys(defaultFilters);

export const MapSidePanel = ({
  open,
  isCompact,
  parentHeight,
  parentWidth,
  children,
}) => {
  const router = useRouter();

  const { lat, lon, searchRadius: querySearchRadius, ...urlFilters } =
    router?.query ?? {};

  const compactStyle = {
    width: `${Math.min(parentWidth, 600)}px`,
    maxHeight: `${parentHeight * 0.75}px`,
  };
  const style = {
    height: `${parentHeight}px`,
    width: `${Math.max(parentWidth * 0.33, 400)}px`,
  };

  const [activePanels, setActivePanels] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(allActions.institutionFilters.resetFilters());
    const acceptableFilters = Object.keys(urlFilters).filter((k) =>
      acceptableFlags.includes(k)
    );
    const hasFilters = acceptableFilters.length > 0;
    if (hasFilters) {
      const preFilters = Object.entries(urlFilters).reduce((acc, [k, v]) => {
        acc[k] = v === 'true' ? true : false;

        return acc;
      }, {});
      // console.debug('prefilters: ', preFilters);
      dispatch(
        allActions.institutionFilters.setFlags({
          ...defaultFilters,
          ...preFilters,
        })
      );
    }
  }, []);

  const institutionFilters = useSelector(
    selectors.institutionFilters.selectAllFilters
  );

  const resetFilters = () =>
    dispatch(allActions.institutionFilters.resetFilters());

  const closePanels = () => setActivePanels([]);

  const searchRadius = useSelector(
    selectors.zipSearch.selectLastSearchedRadius
  );

  const lastSearchedZip = useSelector(
    selectors.zipSearch.selectLastSearchedText
  );

  const filters = useSelector(selectors.institutionFilters.selectAllFilters);

  const filtersActive = Object.values(filters).some((f) => !f);
  const vizCount = useSelector(selectors.map.selectFilteredRssdCount);

  return (
    <div>
      <Collapsible
        open={open}
        direction={isCompact ? 'vertical' : 'horizontal'}
      >
        <Box
          flex={false}
          style={isCompact ? compactStyle : style}
          background={'light-1'}
          elevation={'medium'}
        >
          <Box
            fill={'horizontal'}
            background={'brand'}
            pad={'xsmall'}
            flex={false}
          >
            <Text
              textAlign={'center'}
              size={'large'}
              margin={'none'}
              color={'white'}
              weight={'bold'}
            >
              Top {vizCount} banks within {searchRadius} mile
              {searchRadius !== 1 ? 's' : ''} of {lastSearchedZip}
            </Text>
          </Box>
          <Box flex={false}>
            <Accordion
              activeIndex={activePanels}
              onActive={(panels) => setActivePanels(panels)}
            >
              <AccordionPanel
                label={
                  <Box pad={'xsmall'}>
                    <Text textAlign={'center'} size={'small'}>
                      Update Search
                    </Text>
                  </Box>
                }
              >
                <Box pad={'small'} align={'center'} justify={'center'}>
                  <NewSearchComponent beforeSearch={closePanels} />
                  {/* <SplashSearch hideSearch={closePanels} /> */}
                </Box>
              </AccordionPanel>
              <AccordionPanel
                label={
                  <Box pad={'xsmall'}>
                    <Text textAlign={'center'} size={'small'}>
                      Filter Institutions
                      {filtersActive && (
                        <Text color={'red'} size={'xsmall'}>
                          {'  '}(You have applied filters)
                        </Text>
                      )}
                    </Text>
                  </Box>
                }
              >
                <>
                  <InstitutionFilters />
                  <Button
                    alignSelf={'center'}
                    size={'small'}
                    icon={<Refresh size={'small'} />}
                    label={<Text size={'xsmall'}>Reset Filters</Text>}
                    onClick={resetFilters}
                    disabled={!filtersActive}
                    margin={{ bottom: 'small' }}
                  />
                </>
              </AccordionPanel>
            </Accordion>
          </Box>
          <SearchResultComponent
            showControlButtons={false}
            showSearchOnEmpty={false}
          />
        </Box>
      </Collapsible>
      {children}
    </div>
  );
};

import React from 'react';
import { useEffect } from 'react';
import {
  Box,
  TextInput,
  Button,
  Text,
  Form,
  FormField,
  Tabs,
  Tab,
  Layer,
  Stack,
} from 'grommet';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Spinning } from 'grommet-controls';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, allActions } from 'reduxConfig';
import { LogoutButton, AdminSuccessBox } from './components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const AdminPanel = React.lazy(() => import('./AdminPanel'));
const CustomFlags = React.lazy(() => import('./CustomFlags'));
const CustomHeader = React.lazy(() => import('./CustomHeaderTab'));

const AdminHeader = () => {
  const user = useSelector(selectors.auth.selectUser);

  return (
    <Box
      pad={'small'}
      fill={'horizontal'}
      background={'brand'}
      align={'center'}
      direction={'row-responsive'}
      justify={'between'}
    >
      <Text color={'light-1'}>Greetings, {user}</Text>
      <LogoutButton />
    </Box>
  );
};

const AdminTabs = () => {
  return (
    <Box fill>
      <AdminHeader />
      <Tabs>
        <Tab title={'Bank Admin'}>
          <React.Suspense fallback={<div>Loading Admin Panel...</div>}>
            <AdminPanel />
          </React.Suspense>
        </Tab>
        <Tab title={'Custom Flags'}>
          <React.Suspense fallback={<div>Loading Custom Flags Panel...</div>}>
            <CustomFlags />
          </React.Suspense>
        </Tab>
        <Tab title={'Custom Header'}>
          <React.Suspense fallback={<div>Loading Custom Flags Panel...</div>}>
            <CustomHeader />
          </React.Suspense>
        </Tab>
      </Tabs>
      <ToastContainer autoClose={1750} />
    </Box>
  );
};

const DOMAIN = 'auth.betterbankingoptions.com';
const CLIENT_ID = '2htcf9r0vu21rcbh0b3p7fbb6k';
const ENC = encodeURIComponent;

const HostedUiRedirect = () => {
    useEffect(() => {
        const redirectBackToUs = window.location.protocol + '//' + window.location.host + '/admin/signed-in';
        const redirectToThem = `https://${DOMAIN}/login?response_type=token&` +
              `client_id=${ENC(CLIENT_ID)}&redirect_uri=${ENC(redirectBackToUs)}`;
        window.location.assign(redirectToThem);
    });
    return null;
};

const HostedUiLogoutRedirect = () => {
    useEffect(() => {
        const redirectBackToUs = window.location.protocol + '//' + window.location.host + '/admin/signed-out';
        const redirectToThem = `https://${DOMAIN}/logout?` +
            `client_id=${ENC(CLIENT_ID)}&logout_uri=${ENC(redirectBackToUs)}`;
        // console.debug(`HostedUiLogoutRedirect: ${redirectToThem}`);
        window.location.assign(redirectToThem);
    });
    return null;
};

const Admin = () => {
  const isAuthenticated = useSelector(selectors.auth.selectAuthenticated);
  if (isAuthenticated) {
    return (
      <Box fill justify={'center'} align={'center'}>
        <AdminTabs />
      </Box>
    );
  }
  return (
    <HostedUiRedirect />
  );
};

const AdminLogout = () => {
  return (
    <HostedUiLogoutRedirect />
  );
};

// once you're logged in, you're redirected to:
//     <protocol>://<host>/admin/signed-in#access_token=<token>&expires_in=3600&token_type=Bearer
// which is routed to this component.
const AdminSignedIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        const usp = new URLSearchParams(window.location.hash.replace(/^#/, ''));
        const accessToken = usp.get('access_token'); // these don't seem to work :-(
        const idToken = usp.get('id_token');
        const tokenType = usp.get('token_type');
        console.debug(`ACCESS TOKEN IS ${accessToken}`);
        console.debug(`ID TOKEN IS ${idToken}`);
        // const accessTokenPayload = jwt_decode(accessToken);
        const idTokenPayload = jwt_decode(idToken);
        const email = idTokenPayload.email;
        // const displayName = idTokenPayload.name;
        axios.defaults.headers.common['Authorization'] = `${tokenType} ${idToken}`;
        const payload = { tokenType, idToken, 'user': email };
        console.log(`payload is ${JSON.stringify(payload, null, 4)}`);
        dispatch(allActions.auth.loginSuccess(payload));
        history.replace('/admin');
    });
    return null;
};

const AdminSignedOut = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        // console.debug(`AdminSignedOut useEffect`);
        axios.defaults.headers.common['Authorization'] = undefined;
        dispatch(allActions.auth.logout());
        // history.replace('/admin');
    });
    return null;
};

export const AdminComponent = Admin;
export const AdminLogoutComponent = AdminLogout;
export const AdminSignedInComponent = AdminSignedIn;
export const AdminSignedOutComponent = AdminSignedOut;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isFetching: false,
  isError: false,
  isLoaded: false,
  errorMessage: '',
  bankDetails: undefined
}

const bankDetailSlice = createSlice({
  name: 'bankDetail',
  initialState,
  reducers: {
    startGetBankDetail (state, action) {
      state.isFetching = true
      state.isError = false
      state.isLoaded = false
      state.errorMessage = ''
      state.bankDetails = undefined
    },
    finishGetBankDetailSuccess (state, action) {
      state.isFetching = false
      state.isError = false
      state.isLoaded = true
      state.errorMessage = ''
      state.bankDetails = action.payload
    },
    finishGetBankDetailFailure (state, action) {
      state.isFetching = false
      state.isError = true
      state.isLoaded = false
      state.errorMessage = action.payload
      state.bankDetails = undefined
    }
  }
})

export const {
  startGetBankDetail,
  finishGetBankDetailSuccess,
  finishGetBankDetailFailure
} = bankDetailSlice.actions

export default bankDetailSlice.reducer

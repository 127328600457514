import React from 'react';
import { Box } from 'grommet';
import { useRouter } from 'app/hooks';
import { allActions, selectors } from 'reduxConfig';
import { useSelector, useDispatch } from 'react-redux';
import { ZipSearchResults } from './ZipSearchResults';
import { ZipSearchError } from './ZipSearchError';
import { ZipSearchFetching } from './ZipSearchFetching';
import { ZipSearchNoResults } from './ZipSearchNoResults';
import { useEffectOnce } from 'react-use';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';

const validateQuery = (query) => {
  const { lat, lon, searchRadius } = query;
  return isNumber(+lat) && isNumber(+lon) && isNumber(+searchRadius);
};

export const SearchResultComponent = ({
  showControlButtons = true,
  showSearchOnEmpty = true,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isFetching = useSelector(selectors.zipSearch.selectSearchFetching);
  const isError = useSelector(selectors.zipSearch.selectSearchError);
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
  const searchText = useSelector(selectors.zipSearch.selectSearchText);
  const countOfReturnedBanks = useSelector(
    selectors.stateData.selectReturnedBankCount
  );

  useEffectOnce(() => {
    const queryValid = validateQuery(router.query);
    // if not fetching AND no query in URL, then redirect to search page
    if (!isFetching && !queryValid) {
      router.replace('/search/zip');
      return;
    }
    // if not fetching, and a query does exist
    if (!isFetching && queryValid) {
      const coordString = `[${round(router.query.lon, 4)}, ${round(
        router.query.lat,
        4
      )}]`;
      dispatch(allActions.fullZipSearch.setLastSearchedText(coordString));
      dispatch(allActions.fullZipSearch.updateSearchText(coordString));
      dispatch(
        allActions.fullZipSearch.updateSearchCoords({
          lat: +router.query.lat,
          lon: +router.query.lon,
        })
      );
      dispatch(
        allActions.fullZipSearch.setSearchRadius(+router.query.searchRadius)
      );
      dispatch(allActions.fullZipSearch.startZipSearch());
    }
  }, []);

  return (
    <Box fill justify={'center'}>
      {isFetching && !isError && <ZipSearchFetching />}
      {isError && !isFetching && (
        <ZipSearchError showSearchOnEmpty={showSearchOnEmpty} />
      )}
      {!isError && !isFetching && countOfReturnedBanks > 0 && (
        <ZipSearchResults showControlButtons={showControlButtons} />
      )}
      {!isError && !isFetching && countOfReturnedBanks === 0 && (
        <ZipSearchNoResults showSearchOnEmpty={showSearchOnEmpty} />
      )}
    </Box>
  );
};

export * from './ZipSearchResults';
export * from './ZipSearchError';
export * from './ZipSearchFetching';
export * from './ZipSearchNoResults';
export * from './MdiSearchResults';
export default SearchResultComponent;

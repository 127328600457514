import styled from 'styled-components'

export const NavigationContainer = styled.div({
  position: 'absolute',
  margin: 10,
  marginBottom: 30,
  bottom: 0,
  right: 0,
  zIndex: 1
})

import React from 'react';
import { Box, Heading, Text, Accordion, AccordionPanel } from 'grommet';
import { useGeolocation } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { allActions, selectors } from 'reduxConfig';
import { ZipSearchResults, MdiSearchResults } from 'app/grommetComponents';
import { Row } from 'components/atoms';
import { useCompact } from 'app/hooks';
import { Definitions } from './Definitions';
import { Spinning } from 'grommet-controls';
import appConfig from '../../../config';

export const MdiPage = () => {
    const { error, latitude, longitude, loading } = useGeolocation({
        maximumAge: 10 * 60 * 1000,
        timeout: appConfig.timeout,
        enableHighAccuracy: false,
    });

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!loading) {
            dispatch(allActions.fullZipSearch.setSearchRadius(30));
            if (latitude && longitude) {
                dispatch(
                    allActions.fullZipSearch.updateSearchCoords({
                        lat: latitude,
                        lon: longitude,
                    })
                );
                dispatch(allActions.fullZipSearch.startMdiSearch());
            }
        }
    }, [loading, latitude, longitude, dispatch]);

    const banks = useSelector(selectors.stateData.selectAllBanks);

    const isCompact = useCompact(['xsmall', 'small']);

    return (
        <Box>
            <Definitions />
            {loading ? (
                <Box fill align={'center'} justify={'center'}>
                    <Spinning size={'large'} />
                </Box>
            ) : (
                <MdiSearchResults sortByDistance />
            )}
        </Box>
    );
};

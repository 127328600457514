import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lightMode: true
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme (state, action) {
      state.lightMode = !state.lightMode
    }
  }
})

export const { toggleTheme } = themeSlice.actions

export default themeSlice.reducer

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allActions } from 'reduxConfig';
import { SearchButtonUI } from 'components/cells';
import { useRouter } from 'app/hooks';
import { useKey } from 'react-use';
import { selectors } from 'reduxConfig';

export const SearchButton = ({ beforeSearch, onPress, ...rest }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
  const searchCoords = useSelector(selectors.zipSearch.selectSearchCoords);
  const searchText = useSelector(selectors.zipSearch.selectSearchText);
  const searchCoordsValid = !!searchCoords.lat && !!searchCoords.lon;

  const handleSearch = () => {
    if (searchCoordsValid && searchText.length > 0) {
      if (beforeSearch) {
        beforeSearch();
      }
      if (onPress) {
        onPress();
      } else {
        dispatch(allActions.fullZipSearch.startZipSearch());
        router.push({
          pathname: '/map',
          search: `?lat=${searchCoords.lat}&lon=${searchCoords.lon}&searchRadius=${searchRadius}`,
          state: { ...searchCoords, searchRadius: searchRadius },
        });
      }
    }
  };

  useKey((evt) => evt.key === 'Enter', handleSearch);

  return (
    <SearchButtonUI
      disabled={!searchCoordsValid || searchText.length === 0}
      onClick={handleSearch}
      {...rest}
    />
  );
};

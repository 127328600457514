const qctPresenceEditable = {
  label: 'Qualified Tract Presence',
  headerText: 'Qualified Tract Presence',
  hookText: 'Serving the Underserved',
  blurbText: `Qualified Tract Presence indicates a bank's commitment to low- and moderate-income individuals and communities, based on where the bank has chosen to locate its branches. Essentially, this means we look for banks and credit unions which have branches in low- and moderate-income neighborhoods, as those communities are often neglected by traditional banking options.`,
  definition: [
    `A Qualified Census Tract (QCT) is any census tract (or equivalent geographic area defined by the Census Bureau) in which at least 50% of households have an income less than 60% of the Area Median Gross Income (AMGI). The Qualified Tract Presence score is the percentage of a bank's branches that are located within the geographic bounds of a qualified census tract. Roughly 20% of all census tracts in the United States are considered qualified.`,
    `Unfortunately, most banks and credit unions are not required to report whether their loans are made in low- and moderate-income communities. In a sense, we use the Qualified Tract Presence as a proxy for that unreported data, knowing people tend to place deposits in and get loans from banks and credits unions near them. Some banks are required to report such loan data, but because it’s only available for a small subset of our banks and credit unions, we do not include it in the overall score.`
  ]
}

const qctPresenceNotEditable = {
  name: 'qctPresence',
  to: '/methodology/qualified-tract-presence',
  iconSrc: '/qctPresence.svg',
  link: 'qualified-tract-presence',

  weight: 0.2,
  color: 'qctPresence',
  weights: [
    { name: 'qualityLending', label: 'Quality Lending', weight: 0.6 },
    { name: 'qctPresence', label: 'Qualified Tract Presence', weight: 0.2 }
  ]
}

export const qctPresence = {
  ...qctPresenceEditable,
  ...qctPresenceNotEditable
}

import React from 'react'
import { Heading, Text, Anchor, Box, Paragraph } from 'grommet'
import { HeaderBar } from 'components/atoms'
import { sampleBlogPosts } from 'app/content/sample_blog_posts'

const RenderPost = ({ title, author, publishDate, content, link }, idx) => {
  return (
    <Box
      key={link}
      background={'light-3'}
      elevation={'medium'}
      border={{ size: 'xsmall', color: 'background-back' }}
      align={'center'}
      round={'small'}
      overflow={'hidden'}
      flex={'grow'}
      basis={'1/2'}
    >
      <HeaderBar background={'brand'}>
        <Anchor href={link} rel='noopener noreferrer'>
          <Heading textAlign={'center'} color={'light-3'} level={'2'}>
            {title}
          </Heading>
        </Anchor>
      </HeaderBar>
      <Box pad={'small'}>
        <Text color={'dark-2'} size={'xsmall'} textAlign={'center'}>
          {publishDate} | {author}
        </Text>
      </Box>

      <Box padding={'small'}>
        <Paragraph size={'medium'} textAlign={'start'}>
          {content}
        </Paragraph>
      </Box>
    </Box>
  )
}

export const RecentPosts = () => {
  return (
    <Box>
      <Box pad={{ horizontal: 'small' }} align={'center'}>
        <Heading
          textAlign={'center'}
          margin={'none'}
          level={'3'}
          size={'small'}
          color={'brand'}
        >
          Recent Posts
        </Heading>
      </Box>
      <Box
        width={{ max: 'xlarge' }}
        gap={'medium'}
        direction={'row-responsive'}
        pad={'medium'}
        // align={'center'}
        alignSelf={'center'}
      >
        {sampleBlogPosts.map(RenderPost)}
      </Box>
    </Box>
  )
}

export const BlogSection = () => {
  return (
    <Box flex={false} fill={'horizontal'} background={'light-1'}>
      <Box pad={'medium'} align={'center'}>
        <Text textAlign={'center'}>
          Please visit our blog for in-depth bank profiles and other related
          content
        </Text>
      </Box>
      <RecentPosts />
    </Box>
  )
}

import React from 'react';
import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionPanel,
  Button,
  Stack,
} from 'grommet';
import { useGeolocation } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { allActions, selectors } from 'reduxConfig';
import { ZipSearchResults, MdiSearchResults } from 'app/grommetComponents';
import { Row } from 'components/atoms';
import { useCompact, useRouter } from 'app/hooks';
import { Close } from 'grommet-icons';

export const Definitions = () => {
  const { lat, lon } = useSelector(selectors.zipSearch.selectSearchCoords);
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);

  const router = useRouter();

  const navToMap = () => {
    router.push({
      pathname: '/map',
      search: `?lat=${lat}&lon=${lon}&searchRadius=${searchRadius}&mdi=true&mcu=true`,
      state: {
        lat,
        lon,
        searchRadius: searchRadius,
        mdi: true,
        mcu: true,
      },
    });
  };

  const [defsVisible, setDefsVisible] = React.useState(false);

  const hide = () => setDefsVisible(false);

  return (
    <Box style={{ flex: 0, flexGrow: 1 }}>
      <Heading level={2} size={'medium'} alignSelf={'center'}>
        Minority Depository Institutions
      </Heading>
      <Row style={{ justifyContent: 'center', padding: 5 }}>
        <Button
          label={'View Definitions'}
          onClick={() => setDefsVisible(true)}
          style={{ marginRight: 5 }}
        />
        <Button
          style={{ marginLeft: 5 }}
          label={'View on Map'}
          onClick={navToMap}
        />
      </Row>
      {defsVisible && (
        <Stack fill anchor={'top-right'}>
          <Box
            fill
            justify={'center'}
            align={'center'}
            pad={{
              top: 'large',
              left: 'small',
              right: 'small',
              bottom: 'small',
            }}
          >
            <Box direction={'row-responsive'} fill align={'start'}>
              <Box fill pad={'small'} align={'start'}>
                <Heading level={2} size={'small'} fill={'horizontal'}>
                  Minority Depository Institutions (Credit Unions, MCU)
                </Heading>
                <Text>
                  "To qualify as a minority depository institution in the policy
                  statement, a federally insured credit union’s percentage of
                  potential minority members, current minority members and
                  minority board members must each exceed 50 percent."
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.ncua.gov/support-services/credit-union-resources-expansion/resources/minority-depository-institution-preservation'
                    }
                  >
                    Read More
                  </a>
                </Text>
              </Box>

              <Box fill pad={'small'} justify={'start'}>
                <Heading level={2} size={'small'}>
                  Minority Depository Institutions (Banks, MDI)
                </Heading>
                <Text>
                  "...[A] minority depository institution is defined as any
                  Federally insured depository institution where 51 percent or
                  more of the voting stock is owned by minority individuals.
                  This includes institutions collectively owned by a group of
                  minority individuals, such as a Native American Tribe.
                  Ownership must be by U.S. citizens or permanent legal U.S.
                  residents to be counted in determining minority ownership. In
                  addition to the institutions that meet the ownership test, for
                  the purposes of this Policy Statement, institutions will be
                  considered minority depository institutions if a majority of
                  the Board of Directors is minority and the community that the
                  institution serves is predominantly minority."
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.fdic.gov/regulations/laws/rules/5000-2600.html#fdic5000policyso'
                    }
                  >
                    Read More
                  </a>
                </Text>
              </Box>
              <Box fill pad={'small'}>
                <Heading level={2} size={'small'}>
                  How do banks and credit unions define "minority"?
                </Heading>
                <Text>
                  The term `minority` means any black American, Native American,
                  Hispanic American, or Asian American.
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.congress.gov/bill/101st-congress/house-bill/1278/text'
                    }
                  >
                    Read more from section 308 of the Financial Institutions
                    Reform, Recovery, and Enforcement Act of 1989
                  </a>
                </Text>
              </Box>
            </Box>
          </Box>
          <Button icon={<Close />} onClick={hide} />
        </Stack>
      )}
      {/* <Accordion>
        <AccordionPanel label={'View Definitions'}>
          <Box flex={'grow'}>
            <Box direction={'row-responsive'} fill align={'start'}>
              <Box fill pad={'small'} align={'start'}>
                <Heading level={2} size={'small'} fill={'horizontal'}>
                  Minority Depository Institutions (Credit Unions, MCU)
                </Heading>
                <Text>
                  "To qualify as a minority depository institution in the policy
                  statement, a federally insured credit union’s percentage of
                  potential minority members, current minority members and
                  minority board members must each exceed 50 percent."
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.ncua.gov/support-services/credit-union-resources-expansion/resources/minority-depository-institution-preservation'
                    }
                  >
                    Read More
                  </a>
                </Text>
              </Box>

              <Box fill pad={'small'} justify={'start'}>
                <Heading level={2} size={'small'}>
                  Minority Depository Institutions (Banks, MDI)
                </Heading>
                <Text>
                  "...[A] minority depository institution is defined as any
                  Federally insured depository institution where 51 percent or
                  more of the voting stock is owned by minority individuals.
                  This includes institutions collectively owned by a group of
                  minority individuals, such as a Native American Tribe.
                  Ownership must be by U.S. citizens or permanent legal U.S.
                  residents to be counted in determining minority ownership. In
                  addition to the institutions that meet the ownership test, for
                  the purposes of this Policy Statement, institutions will be
                  considered minority depository institutions if a majority of
                  the Board of Directors is minority and the community that the
                  institution serves is predominantly minority."
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.fdic.gov/regulations/laws/rules/5000-2600.html#fdic5000policyso'
                    }
                  >
                    Read More
                  </a>
                </Text>
              </Box>
              <Box fill pad={'small'}>
                <Heading level={2} size={'small'}>
                  How do banks and credit unions define "minority"?
                </Heading>
                <Text>
                  The term `minority` means any black American, Native American,
                  Hispanic American, or Asian American.
                  <br />
                  <br />
                  <a
                    href={
                      'https://www.congress.gov/bill/101st-congress/house-bill/1278/text'
                    }
                  >
                    Read more from section 308 of the Financial Institutions
                    Reform, Recovery, and Enforcement Act of 1989
                  </a>
                </Text>
              </Box>
            </Box>
          </Box>
        </AccordionPanel>
      </Accordion> */}
    </Box>
  );
};

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from 'grommet'

export const HeaderBar = ({
  text,
  size,
  level,
  headingProps,
  showIcons,
  children,
  ...rest
}) => {
  return (
    <Box
      flex={false}
      fill={'horizontal'}
      background={'background-back'}
      direction={'row'}
      align={'center'}
      justify={'center'}
      pad={'small'}
      border={{ side: 'bottom', color: 'cta', size: 'small' }}
      gap={'small'}
      {...rest}
    >
      {showIcons && (
        <img src={'/003-plant.svg'} height={'24px'} width={'24px'} />
      )}
      {text && (
        <Heading
          color={'#fff'}
          level={level}
          size={size}
          margin={'none'}
          {...headingProps}
        >
          {text}
        </Heading>
      )}
      {!text && children}
      {showIcons && (
        <img
          style={{ transform: 'scale(-1, 1)' }}
          src={'/003-plant.svg'}
          height={'24px'}
          width={'24px'}
        />
      )}
    </Box>
  )
}

HeaderBar.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  level: PropTypes.number,
  headingProps: PropTypes.object,
  showIcons: PropTypes.bool
}

HeaderBar.defaultProps = {
  text: '',
  size: 'medium',
  level: 2,
  headingProps: {},
  showIcons: false
}

import React from 'react'
import { Box, Image, Text } from 'grommet'
import PropTypes from 'prop-types'
import { Divider } from 'components/atoms'
import ReactMarkdown from 'react-markdown'

export const UserInfo = ({ image, name, text, isCompact }) => {
  return (
    <Box pad={'medium'} width={{ max: 'xlarge' }}>
      <Box direction={'row'} gap={'medium'} justify={'start'} align={'start'}>
        <Text margin={'none'} padding={'none'}>
          <Box
            width={isCompact ? '155px' : 'small'}
            height={isCompact ? '155px' : 'small'}
            round={isCompact ? 'small' : 'medium'}
            overflow={'hidden'}
            margin={{ top: 'medium', right: 'medium', bottom: 'small' }}
            border={{ size: 'small', color: 'brand' }}
            style={{ float: 'left', padding: 'small' }}
          >
            <Image src={image} fit={'cover'} alt={`Picture of ${name}`} />
          </Box>
          <ReactMarkdown className={'markdown'} source={text} />
        </Text>
        {/* <Box justify={'start'} align={'center'} width={{ min: 'small' }}> */}
      </Box>
      <Divider />
    </Box>
  )
}

UserInfo.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isCompact: PropTypes.bool.isRequired
}

UserInfo.defaultProps = {
  image: undefined,
  name: 'Replace this Name',
  text: 'Replace this Name',
  isCompact: false
}

import React from 'react'
import { Box, Layer, Text, Button } from 'grommet'
import { BankDetailComponent } from 'app/grommetComponents'

export const DetailPopover = ({ rssd, bankName, open, toggle }) => {
  return !open ? null : (
    <Layer
      position={'center'}
      plain
      modal
      onClickOutside={toggle}
      onEsc={toggle}
      margin={'small'}
      background={'#0004'}
    >
      <Box
        fill
        round={'xsmall'}
        overflow={'hidden'}
        // flex={'shrink'}
        elevation={'medium'}
        border={{ size: 'small', color: 'light-1' }}
      >
        <BankDetailComponent
          rssd={rssd}
          bankName={bankName}
          compact
          closeButton={
            <Button
              fill
              as={'button'}
              label={<Text color={'#fff'}>Close</Text>}
              onClick={toggle}
              // to={navToDetails}
            />
          }
        />
      </Box>
    </Layer>
  )
}

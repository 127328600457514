import React from 'react';
import { Box, Text, Button, Anchor } from 'grommet';
import {
    Checkbox,
    CheckboxSelected,
    CircleInformation as Info,
    Notes,
} from 'grommet-icons';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'components/atoms';
import { TagBar } from 'components/molecules';
import { ScoreMeter } from 'app/grommetComponents';
import { selectors, allActions } from 'reduxConfig';
import minBy from 'lodash/minBy';
import { useCompact } from 'app/hooks';

export const ZipSearchBankItem = ({
    bank,
    index,
    navToDetails,
    showAddress = false,
}) => {
    const isCompact = useCompact(['xsmall', 'small']);
    const dispatch = useDispatch();
    const selectedRssd = useSelector(selectors.stateData.selectSelectedRssd);
    const selected = selectedRssd === bank.rssd;

    const allBranches = Object.values(bank.localBranches);
    const closestBranch = minBy(allBranches, 'properties.distToZip');

    const closestBranchDistance = round(
        closestBranch?.properties?.calculatedDist ?? 0,
        2
    );

    const handleSelect = () => {
        dispatch(allActions.stateData.setSelectedRssd(bank.rssd));
    };

    const selectIconColor = selected ? 'accent-1' : 'light-3';
    const borderColor = selected ? 'cta' : 'brand';
    const selectIcon = selected ? (
        <CheckboxSelected color={selectIconColor} size={'16px'} />
    ) : (
        <Checkbox color={selectIconColor} size={'16px'} />
    );
    const infoIcon = <Info color={'light-3'} size={'16px'} />;
    const bankHasNotes = (bank?.clientDetails?.notes ?? '').length > 0;

    const renderClosestBranchDistance = () =>
          closestBranchDistance ? (
              <Box pad={'xsmall'}>
                  <Text size={'xsmall'}>
                      Closest Branch: {closestBranchDistance} miles
                  </Text>
              </Box>
          ) : null;

    const renderClosestAddress = () => {
        if (!showAddress) return null;
        const {
            siteName,
            address1,
            address2,
            city,
            state,
            zip,
        } = closestBranch.properties;
        return (
            <Box align={'center'} justify={'center'} flex={'grow'}>
                <Text size={'small'}>Closest Branch Address:</Text>
                <Text size={'xsmall'}>{address1}</Text>
                {address2 && <Text size={'xsmall'}>{address2}</Text>}
                <Text size={'xsmall'}>
                    {city}, {state} {zip}
                </Text>
            </Box>
        );
    };

    return (
        <Box
            flex={false}
            fill={'horizontal'}
            key={bank.rssd}
            background={'light-3'}
        >
            <Row
                background={'background-back'}
                border={{
                    side: 'bottom',
                    size: 'small',
                    color: borderColor,
                }}
                pad={'xsmall'}
                align={'center'}
            >
                <Text color={'light-3'} margin={'none'} truncate>
                    {bank.name}
                </Text>
                <Row fill={false} gap={'small'} margin={{ right: 'small' }}>
                    {bankHasNotes && <Notes color={selectIconColor} size={'16px'} />}
                    <Button plain onClick={handleSelect} icon={selectIcon}></Button>
                    <Button plain onClick={navToDetails} icon={infoIcon}></Button>
                </Row>
            </Row>
            <Box
                direction={'row-responsive'}
                fill={'horizontal'}
                pad={{ horizontal: 'small' }}
                background={'light-1'}
            >
                <Box align="center" justify={'center'} flex={'grow'}>
                    <TagBar
                        justify={'center'}
                        flagArray={bank?.flagArray ?? []}
                        isCompact={true}
                    />
                    <Box>{renderClosestBranchDistance()}</Box>
                </Box>
                {renderClosestAddress()}
                <Box align={'center'} justify={'center'} flex={'grow'}>
                    <ScoreMeter bank={bank} />
                    <Anchor
                        as={'a'}
                        onClick={navToDetails}
                        color={'dark-4'}
                        size={'xsmall'}
                    >
                        View Details
                    </Anchor>
                </Box>
            </Box>
        </Box>
    );
};

const content = `The liberal ideal of Southern California is far from reality. It’s an extremely expensive place to live, with the housing costs in the area being 140% more than the national average. Large corporations often influence social and economic policy in the area, leading many liberal policies to have backdoor exceptions. Additionally, like many urban areas in this country, diverse populations are deeply affected by segregation, enforced by redlining in the 20th century...`

const title = `City Survey: Greater Los Angeles`

const publishDate = `May 8, 2024`

const author = `Sara Loving`

const link = `https://www.betterbankingoptions.com/single-post/city-survey-greater-la`

export const left_post = {
  content,
  title,
  publishDate,
  author,
  link
}

import React, { useState } from 'react'
import { Box, Meter, Stack, Text } from 'grommet'
import { useMouse } from 'react-use'
import { useCompact } from 'app/hooks'

export const WeightMeter = ({ weights, centerWeight, weightName, ...rest }) => {
  const [floatLabel, setFloatLabel] = useState()
  const [dropColor, setDropColor] = useState('black')
  const [active, setActive] = useState(undefined)
  const mouseRef = React.useRef(null)
  const { docX, docY } = useMouse(mouseRef)
  const isCompact = useCompact()

  const meterValues = weights.map(({ name: wName, label, weight }, idx) => {
    return {
      value: weight * 100,
      color: wName === weightName ? weightName : `dark-${4 - idx}`,
      label,
      onHover: isOver => {
        setFloatLabel(isOver ? label : undefined)
        setDropColor(isOver ? wName : 'black')
        setActive(isOver ? weight * 100 : undefined)
      }
    }
  })

  return (
    <Box
      flex={false}
      align='center'
      justify={'center'}
      ref={mouseRef}
      pad={'small'}
    >
      <Stack anchor='center'>
        <Meter
          type='circle'
          background='light-4'
          values={meterValues}
          max={100}
          size={isCompact ? 'xsmall' : 'small'}
          thickness={isCompact ? 'small' : 'medium'}
          {...rest}
        />
        <Box align={'center'}>
          <Box align={'center'} justify={'center'} direction={'row'}>
            <Text
              size={isCompact ? 'xlarge' : 'xxlarge'}
              weight='bold'
              textAlign={'center'}
              color={dropColor || 'black'}
            >
              {active || centerWeight * 100}
            </Text>
            <Text size={'small'}>%</Text>
          </Box>
        </Box>
      </Stack>
      {!!floatLabel && (
        <Box
          style={{ position: 'absolute', left: docX, top: docY }}
          round
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          background={'white'}
          elevation={'small'}
          border={{ size: 'xsmall', color: dropColor }}
          margin={'small'}
        >
          <Text color={dropColor} size={'small'}>
            {floatLabel}
          </Text>
        </Box>
      )}
    </Box>
  )
}

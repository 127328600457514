import React, { useState } from 'react';
import { Box, Text, Button } from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { SubtractCircle, AddCircle } from 'grommet-icons';
import { useToggle } from 'react-use';
import { allActions, selectors } from 'reduxConfig';
import { ZipSearchBankItem } from 'app/grommetComponents';
import { DetailPopover } from './DetailPopover';
import { minBy } from 'ramda';
import { Spinning } from 'grommet-controls';

export const MdiSearchResults = ({ sortByDistance = false }) => {
  // hooks
  const dispatch = useDispatch();

  // redux selectors
  const isLoading = useSelector(selectors.zipSearch.selectSearchFetching);
  const topBankCount = useSelector(selectors.stateData.selectTopBankCount);
  const vizCount = useSelector(selectors.map.selectFilteredRssdCount);
  const maxCount = useSelector(selectors.map.selectMaxFilteredCount);
  const selectedBankRssd = useSelector(selectors.stateData.selectSelectedRssd);
  const topBanksArray = useSelector(selectors.map.selectSearchResultsBankData);
  const closestByBranchArray = useSelector(
    selectors.map.selectSearchResultsBankDataByMinDistance
  );

  // local state
  const [detailPopoverVisible, toggleDetailPopover] = useToggle(false);
  const [bankDetails, setBankDetails] = useState({ rssd: '', bankName: '' });

  // handlers
  const navToDetail = ({ rssd, bankName }) => () => {
    setBankDetails({ rssd, bankName });
    toggleDetailPopover();
  };

  const showMore = () => dispatch(allActions.stateData.incrementTopBankCount());
  const showLess = () => dispatch(allActions.stateData.decrementTopBankCount());

  return (
    <Box
      fill
      overflow={'auto'}
      justify={'center'}
      align={'stretch'}
      background={'white'}
      gap={'xsmall'}
    >
      {isLoading && (
        <Box fill={'horizontal'} align={'center'}>
          <Spinning />
        </Box>
      )}
      {(sortByDistance ? closestByBranchArray : topBanksArray).map(
        (bank, idx) => {
          return (
            <ZipSearchBankItem
              bank={bank}
              index={idx}
              key={bank.rssd}
              showSelect={true}
              selected={selectedBankRssd === bank.rssd}
              navToDetails={navToDetail({
                rssd: bank.rssd,
                bankName: bank.name,
              })}
              showAddress={true}
            />
          );
        }
      )}
      {(sortByDistance ? closestByBranchArray : topBanksArray).length === 0 && (
        <Box fill pad={'small'}>
          <Text textAlign={'center'}>No Search Results</Text>
        </Box>
      )}
      <DetailPopover
        open={detailPopoverVisible}
        toggle={toggleDetailPopover}
        {...bankDetails}
      />
    </Box>
  );
};

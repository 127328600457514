import React from 'react'
import { Button, Box, Heading, Text } from 'grommet'
import { links, methodologyContent } from 'app/content/metrics'
import { WeightMeter } from 'app/grommetComponents/HomePageSections/MethodologySection'
import { useTitle } from 'react-use'
import { useRouter, useCompact } from 'app/hooks'

const MethPage = ({ scoreType }) => {
  const isCompact = useCompact(['xsmall', 'small'])
  const router = useRouter()

  const navToPage = linkName => () => router.push(linkName)

  const {
    name: selectedName,
    headerText,
    blurbText = '',
    definition = [],
    weight = 0,
    weights
  } = methodologyContent[scoreType]

  useTitle(headerText)

  return (
    <Box fill>
      <Box fill={'horizontal'} flex={false}>
        <Box direction={'row-responsive'} justify={'evenly'} flex={false}>
          {links.map(({ label, to, name }) => {
            const isActive = name === scoreType
            return (
              <Box
                key={name}
                basis={isCompact ? '1/2' : '1/4'}
                justify={'center'}
                border={
                  isCompact
                    ? { size: '0px' }
                    : {
                        side: 'bottom',
                        size: 'medium',
                        color: isActive ? '#0000' : 'white'
                      }
                }
                round={
                  isActive && !isCompact
                    ? { corner: 'bottom', size: 'xsmall' }
                    : 'none'
                }
                background={name}
                align={'center'}
              >
                <Button fill onClick={navToPage(to)} plain>
                  <Box
                    pad={{ vertical: 'small' }}
                    fill
                    align={'center'}
                    justify={'center'}
                  >
                    <Text
                      textAlign={'center'}
                      // weight={isActive ? 'bold' : 'normal'}
                      size={isCompact ? 'small' : 'medium'}
                      color={isActive ? 'light-1' : 'dark-1'}
                      weight={isActive ? 'bold' : 'normal'}
                    >
                      {label}
                    </Text>
                  </Box>
                </Button>
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box
        flex={false}
        fill={'horizontal'}
        direction={isCompact ? 'column-reverse' : 'row'}
      >
        <Box flex pad={'large'}>
          <Heading level={3}>Description</Heading>
          <Text>{blurbText}</Text>
          <Box gap={'small'} flex={false}>
            <Heading level={3}>Definition</Heading>
            {definition.map(d => (
              <Text key={d}>{d}</Text>
            ))}
          </Box>
        </Box>
        <Box gap={'small'} flex={false} align={'center'}>
          <Heading level={3} textAlign={'center'}>
            Weighting
          </Heading>
          <Box
            align={'center'}
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <WeightMeter
              weights={weights}
              centerWeight={weight}
              weightName={selectedName}
              size={isCompact ? 'small' : 'medium'}
              thickness={'large'}
              background={'#fafafa'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const QualityLending = () => <MethPage scoreType={'qualityLending'} />
export const QualifiedTractPresence = () => (
  <MethPage scoreType={'qctPresence'} />
)
export const BranchDensity = () => <MethPage scoreType={'branchDensity'} />
export const TotalAssets = () => <MethPage scoreType={'totalAssets'} />

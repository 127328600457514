const label = 'Branch Density'
const headerText = 'Branch Density'
const hookText = 'Locally Focused'
const blurbText = `Branch Density indicates a bank or credit union's focus on local community needs. Additionally, higher branch density means your deposits are more likely to be used in your city or neighborhood. This score helps bring local banks to your attention.`

export const branchDensity = {
  label,
  headerText,
  hookText,
  blurbText,
  definition: [
    `For each bank we calculated the centroid of all of its branches, as well as the distance from each branch to the centroid in miles. This score is 100 minus the average distance from a branch to the centroid. This means the closer the branch density score is to 100, the tighter its branches are together. A score of 100 means this is a single-branch institution.`,
    `While single-branch institutions are still worth investing in, remember that branch density only accounts for 10% of our weighting. Ultimately, it doesn't matter how small or local your banking institution is if they're not doing significant quality lending.`
  ]
}

// const branchDensityEditable = {
//   label: 'Branch Density',
//   headerText: 'Branch Density',
//   hookText: 'Locally Focused',
//   blurbText: `Branch Density indicates a bank or credit union's focus on local community needs. Additionally, higher branch density means your deposits are more likely to be used in your city or neighborhood. This score helps bring local banks to your attention.`,
//   definition: [
//     `For each bank we calculated the centroid of all of its branches, as well as the distance from each branch to the centroid in miles. This score is 100 minus the average distance from a branch to the centroid. This means the closer the branch density score is to 100, the tighter its branches are together. A score of 100 means this is a single-branch institution.`,
//     `While single-branch institutions are still worth investing in, remember that branch density only accounts for 10% of our weighting. Ultimately, it doesn't matter how small or local your banking institution is if they're not doing significant quality lending.'
//   ]
// }

// const branchDensityNotEditable = {
//   name: 'branchDensity',
//   to: '/methodology/branch-density',
//   iconSrc: '/branchDensity.svg',
//   link: 'branch-density',
//   weight: 0.1,
//   color: 'branchDensity',
//   weights: [
//     { name: 'qualityLending', label: 'Quality Lending', weight: 0.6 },
//     { name: 'qctPresence', label: 'Qualified Tract Presence', weight: 0.2 },
//     { name: 'branchDensity', label: 'Branch Density', weight: 0.1 }
//   ]
// }

// export const branchDensity = {
//   ...branchDensityEditable,
//   ...branchDensityNotEditable
// }

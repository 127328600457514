import React from 'react';
import {
    Box,
    Heading,
    Text,
    Accordion,
    AccordionPanel,
    Tabs,
    Tab,
    Button,
    Layer,
} from 'grommet';
import { useGeolocation, useToggle } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { allActions, selectors } from 'reduxConfig';
import {
    ZipSearchResults,
    MdiSearchResults,
    SplashSearch,
    HeaderSearch,
    NationalBankItem,
} from 'app/grommetComponents';
import { Row } from 'components/atoms';
import { useCompact, useRouter } from 'app/hooks';
import { Spinning } from 'grommet-controls';
import queryString from 'query-string';
import { LocalTab } from './LocalTab';
import TagListPageHeader from './TagListPageHeader';
import uniq from 'lodash/uniq';
import { apiGet } from 'reduxConfig/sagas/fetchWithCancel';
import { DetailPopover } from 'app/grommetComponents/ZipSearchResults/DetailPopover';
import orderBy from 'lodash/orderBy';
import appConfig from '../../../config';

const makeFlagArray = ({ tagString = '', allFlags }) => {
    const flagNameList = uniq(tagString.split(','));
    const flagArray = flagNameList.reduce((acc, flagName) => {
        const matchingFlag = allFlags.find(
            (f) => f.name == flagName || f.shortLabel == flagName.toUpperCase()
        );
        if (matchingFlag) {
            acc.push(matchingFlag);
        }
        return acc;
    }, []);
    return { flagArray, flagNameList };
};

export const TagListPage = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isCompact = useCompact(['xsmall', 'small']);

    const [activeIndex, setActiveIndex] = React.useState(0);

    const updateIndex = (idx) => setActiveIndex(idx);

    React.useEffect(() => {
        dispatch(allActions.bankAdmin.getFlags());
    }, [dispatch]);

    const allFlags = useSelector(selectors.bankAdmin.selectAllFlags);

    const showLocalTab = router.query.local === 'true';

    const { flagArray, flagNameList } = React.useMemo(() => {
        return makeFlagArray({
            tagString: router.query.tags,
            allFlags,
        });
    }, [router, allFlags]);

    const goToNationalTab = () => setActiveIndex(0);

    const [error, setError] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);
    const [nationalData, setNationalData] = React.useState(undefined);

    const sortedNationalData = React.useMemo(() => {
        return orderBy(nationalData, 'score', 'desc');
    }, [nationalData]);

    const getNationalData = React.useMemo(() => {
        return async () => {
            return apiGet(
                'taglist/national',
                {
                    flags: router.query.tags.split(','),
                },
                {
                    timeout: appConfig.timeout,
                }
            )
                .then(({ data }) => {
                    setLoading(false);
                    // console.debug(data);
                    setNationalData(data);
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err);
                });
        };
    }, [router]);

    React.useEffect(() => {
        getNationalData();
    }, [flagArray]);

    // local state
    const [detailPopoverVisible, toggleDetailPopover] = useToggle(false);
    const [bankDetails, setBankDetails] = React.useState({
        rssd: '',
        bankName: '',
    });

    // handlers
    const navToDetail = ({ rssd, bankName }) => () => {
        setBankDetails({ rssd, bankName });
        toggleDetailPopover();
    };

    return (
        <Box fill flex={'grow'}>
            <TagListPageHeader flagList={flagArray} />
            <Tabs activeIndex={activeIndex} onActive={updateIndex}>
                <Tab title={'National Institutions'}>
                    <Box fill margin={{ top: 'small' }}>
                        {(loading || !nationalData) && (
                            <Box align={'center'} justify={'center'} fill>
                                <Spinning />
                            </Box>
                        )}
                        {nationalData &&
                         sortedNationalData.map((bank) => {
                             return (
                                 <Box key={bank.rssd}>
                                     <NationalBankItem
                                         bank={bank}
                                         navToDetails={navToDetail({
                                             rssd: bank.rssd,
                                             bankName: bank.bankInformation.name,
                                         })}
                                     />
                                 </Box>
                             );
                         })}
                        <DetailPopover
                            open={detailPopoverVisible}
                            toggle={toggleDetailPopover}
                            {...bankDetails}
                        />
                    </Box>
                </Tab>
                <Tab title={'Local Institutions'}>
                    <LocalTab goToNationalTab={goToNationalTab} flagList={flagNameList} />
                </Tab>
            </Tabs>
        </Box>
    );
};

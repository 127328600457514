export const selectAllFilters = (state) => state.institutionFilters;
export const selectFdicActive = (state) => state.institutionFilters.fdic;
export const selectNcuaActive = (state) => state.institutionFilters.ncua;
export const selectMcuActive = (state) => state.institutionFilters.mcu;
export const selectLicuActive = (state) => state.institutionFilters.licu;
export const selectFdicCdfiActive = (state) =>
  state.institutionFilters.cdfi_fdic;
export const selectNcuaCdfiActive = (state) =>
  state.institutionFilters.cdfi_ncua;
export const selectCommunityBankActive = (state) =>
  state.institutionFilters.communityBank;
export const selectMdiActive = (state) => state.institutionFilters.mdi;
export const selectOtherFdicActive = (state) =>
  state.institutionFilters.other_fdic;
export const selectOtherNcuaActive = (state) =>
  state.institutionFilters.other_ncua;
export const selectActiveFilterNames = (state) => {
  const activeFilterNames = Object.entries(state.institutionFilters).reduce(
    (acc, [k, v]) => {
      if (!!v) {
        acc.push(k);
      }
      return acc;
    },
    []
  );
  return activeFilterNames;
};

import React from 'react'
import { Box, Button, Text } from 'grommet'

export const MapSidePanelButton = ({
  isCompact,
  onClick,
  banksVisible,
  ...rest
}) => (
  <Button plain onClick={onClick} flex={'shrink'}>
    <Box
      background={'accent-1'}
      pad={
        isCompact
          ? { left: 'small', right: 'small', top: 'small', bottom: 'large' }
          : 'small'
      }
      round={{ corner: isCompact ? 'top' : 'right', size: 'medium' }}
      elevation={'medium'}
      {...rest}
    >
      <Text weight={'bold'} color={'white'}>
        {banksVisible ? 'Hide Banks' : 'Show Banks'}
      </Text>
    </Box>
  </Button>
)

import { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import { useWindowSize } from 'react-use'

export function useCompact (sizes = ['xsmall', 'small', 'medium']) {
  const screenSize = useContext(ResponsiveContext)
  return sizes.includes(screenSize)
}

export function useCheckVertical () {
  const { width, height } = useWindowSize()
  return height > width
}

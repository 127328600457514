const qualityLendingEditable = {
  label: 'Quality Lending',
  headerText: 'Quality Lending',
  hookText: 'Building Community Wealth',
  blurbText: `This score represents a bank's commitment to provide its communities with access to wealth building loans: home mortgages and small business loans. This kind of lending enables families and neighborhoods to bring more capital into their local economies.`,
  definition: [
    `Quality lending is the sum of first-mortgage, family housing loans outstanding and small business loans outstanding as a percentage of a bank's total assets.`,
    `By using total assets, we also account for whether the bank or credit union is effectively loaned out, using their assets for lending instead of parking their assets in investments that are less productive for the community or engaging in speculative trading as the mega-banks do.`
  ]
}

const qualityLendingNotEditable = {
  name: 'qualityLending',
  to: '/methodology/quality-lending',
  iconSrc: '/qualityLending.svg',
  link: 'quality-lending',
  weight: 0.6,
  color: 'qualityLending',
  weights: [{ name: 'qualityLending', label: 'Quality Lending', weight: 0.6 }]
}

export const qualityLending = {
  ...qualityLendingEditable,
  ...qualityLendingNotEditable
}

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grommet } from 'grommet';
import { useTitle } from 'react-use';

// pages
import {
  HomePage,
  MapPage,
  DetailPage,
  SearchPage,
  withAppLayout,
  NotFoundPage,
  QualityLending,
  QualifiedTractPresence,
  BranchDensity,
  TotalAssets,
  AdminComponent,
  AdminLogoutComponent,
  AdminSignedInComponent,
  AdminSignedOutComponent,
  MdiPage,
  TagListPage,
} from './pages';
import * as homePageSections from './grommetComponents/HomePageSections';
// grommet custom theme
import { myCustomTheme } from 'theme';
// redux actions

const appRoutes = {
  home: {
    path: '/',
    exact: true,
    label: 'Better Banking',
    showSearch: false,
    stickyFooter: false,
    component: HomePage,
  },
  map: {
    path: '/map',
    exact: false,
    label: 'Better Banking Map',
    showSearch: false,
    stickyFooter: false,
    component: MapPage,
  },
  zipSearch: {
    path: '/search',
    exact: true,
    label: 'Find a Bank',
    showSearch: false,
    stickyFooter: true,
    component: SearchPage,
  },
  details: {
    path: '/detail/:bankRssd',
    exact: true,
    label: 'Bank Details',
    showSearch: true,
    stickyFooter: false,
    component: DetailPage,
  },
  methodology: {
    path: '/methodology',
    exact: true,
    label: 'Metrics',
    showSearch: true,
    stickyFooter: false,
    component: homePageSections.MethodologySection,
  },
  qualityLending: {
    path: '/methodology/quality-lending',
    exact: true,
    label: 'Quality Lending',
    showSearch: true,
    stickyFooter: false,
    component: QualityLending,
  },
  qctPresence: {
    path: '/methodology/qualified-tract-presence',
    exact: true,
    label: 'QCT Presence',
    showSearch: true,
    stickyFooter: false,
    component: QualifiedTractPresence,
  },
  branchDensity: {
    path: '/methodology/branch-density',
    exact: true,
    label: 'Branch Density',
    showSearch: true,
    stickyFooter: false,
    component: BranchDensity,
  },
  totalAssets: {
    path: '/methodology/asset-size',
    exact: true,
    label: 'Asset Size',
    showSearch: true,
    stickyFooter: false,
    component: TotalAssets,
  },
  admin: {
    path: '/admin',
    exact: true,
    label: 'Admin',
    stickyFooter: false,
    component: AdminComponent,
  },
  adminLogout: {
    path: '/admin/logout',
    exact: true,
    label: 'Log Out',
    stickyFooter: false,
    component: AdminLogoutComponent,
  },
  adminSignedIn: {
    path: '/admin/signed-in',
    exact: true,
    label: 'Signed In',
    stickyFooter: false,
    component: AdminSignedInComponent,
  },
  adminSignedOut: {
    path: '/admin/signed-out',
    exact: true,
    label: 'Signed Out',
    stickyFooter: false,
    component: AdminSignedOutComponent,
  },
  mdi: {
    path: '/bank-black',
    exact: true,
    label: 'Bank Black',
    stickyFooter: false,
    component: MdiPage,
  },
  tagList: {
    path: '/tagList',
    exact: false,
    label: 'Tag List',
    stickyFooter: true,
    component: TagListPage,
  },
};

const IndexApp = () => {
  useTitle('Better Banking');
  return (
    <Grommet theme={myCustomTheme} full>
      <Router>
        <Switch>
          {/* <Redirect exact from={'/'} to={'/home'} /> */}
          {Object.entries(appRoutes).map(
            ([
              routeName,
              {
                path,
                params = [],
                exact = false,
                component,
                label,
                showSearch,
                stickyFooter,
              },
            ]) => {
              return (
                <Route
                  key={routeName}
                  exact={exact}
                  path={path}
                  title={label}
                  component={withAppLayout({ showSearch, stickyFooter, label })(
                    component
                  )}
                />
              );
            }
          )}
          <Route
            component={withAppLayout({
              showSearch: false,
              stickyFooter: false,
              label: '404 Not Found',
            })(NotFoundPage)}
          />
        </Switch>
      </Router>
    </Grommet>
  );
};

export default IndexApp;

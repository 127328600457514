const searchLink = {
  name: 'search',
  label: 'Search',
  sideLabel: 'Find a Bank',
  path: '/search',
  useAnchor: false
}

const blogLink = {
  name: 'blog',
  label: 'Blog',
  sideLabel: 'Our Blog',
  path: 'https://www.betterbankingoptions.com/',
  useAnchor: true
}

const methodologyLink = {
  name: 'methodology',
  label: 'Metrics',
  sideLabel: 'Our Metrics',
  path: '/methodology/quality-lending',
  useAnchor: false
}

const aboutLink = {
  name: 'aboutUs',
  label: 'About',
  sideLabel: 'About Us',
  path: 'https://www.betterbankingoptions.com/our-mission',
  useAnchor: true
}

export const links = [searchLink, methodologyLink, blogLink, aboutLink]

import React from 'react'
import { Box, Text } from 'grommet'
import { RenderScoreSection } from './RenderScoreSection'
import { methodologyContent as scoreTypes } from 'app/content/metrics'

export const MethodologySection = () => {
  return (
    <Box fill={'horizontal'} justify={'center'} flex={false}>
      <Box
        flex={false}
        align={'center'}
        alignSelf={'center'}
        pad={'medium'}
        width={{ max: 'xlarge' }}
      >
        <Text textAlign={'center'}>
          We rank more than 10,000 banks and credit unions in the United States
          by how well they support their local communities. By synthesizing
          publically available banking data, we generate a score (1 - 100) for
          each bank. So what makes a bank a "Better Bank"?
        </Text>
      </Box>
      <Box direction={'row'} wrap pad={'small'} justify={'center'}>
        {Object.values(scoreTypes).map(RenderScoreSection)}
      </Box>
    </Box>
  )
}

MethodologySection.propTypes = {}

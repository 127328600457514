import React from 'react';
import { Box, Text } from 'grommet';
import { useRouter } from 'app/hooks';

export const NotFoundPage = () => {
  const router = useRouter();
  return (
    <Box fill>
      <Text>This page doesn't seem to exist</Text>
      <Text>{JSON.stringify(router, null, 2)}</Text>
    </Box>
  );
};

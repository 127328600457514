import { createSelector } from 'reselect'

export const selectLoading = state => state.bankAdmin.loading
export const selectLoadingMessage = state => state.bankAdmin.loadingMessage
export const selectError = state => state.bankAdmin.error
export const selectErrorMessage = state => state.bankAdmin.errorMessage
export const selectInstitution = state => state.bankAdmin.institution
export const selectInstitutionNotes = state =>
  state.bankAdmin.institution.clientDetails.notes
export const selectSuccessMessage = state => state.bankAdmin.successMessage
export const selectDisplaySuccess = state => Boolean(selectSuccessMessage)
export const selectClientDetails = state => state.bankAdmin.clientDetails
export const selectAllFlags = state => state.bankAdmin.allFlags

export const selectClientDetailsNotes = state =>
  state.bankAdmin.clientDetails.notes
export const selectClientDetailsNotesHistory = state =>
  state.bankAdmin.clientDetails.notesHistory
export const selectClientDetailsFlags = state =>
  state.bankAdmin.clientDetails.flags
export const selectClientDetailsName = state =>
  state.bankAdmin.clientDetails.name

export const selectFlags = createSelector(
  selectInstitution,
  institution => institution?.flags ?? {}
)

export const selectFlagLabels = createSelector(
  selectInstitution,
  institution => institution?.flagLabels ?? {}
)

export const selectNotesText = createSelector(
  selectInstitution,
  institution => institution?.clientDetails?.notes ?? ''
)

export const selectNotesHistory = createSelector(
  selectInstitution,
  institution => institution?.clientDetails?.history ?? []
)

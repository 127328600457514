import React from 'react';
import { Box, Button, Heading } from 'grommet';
import { TagBar } from 'components/molecules';

const TagListPageHeader = ({ flagList }) => {
  return (
    <Box fill={'horizontal'} justify={'center'} align={'center'}>
      <TagBar
        fill={'horizontal'}
        showFull
        flagArray={flagList}
        size={'medium'}
        justify={'center'}
        width={{ max: 'xlarge' }}
      />
    </Box>
  );
};

export default TagListPageHeader;

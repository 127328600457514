import { put, cancelled, select } from 'redux-saga/effects';
import { allActions } from 'reduxConfig/reducers';
import { apiGet, cancellableFetch } from './fetchWithCancel';
import { selectors } from 'reduxConfig';
import qs from 'query-string';

const processSuggestions = (results = []) => {
    return results.reduce((acc, sugg) => {
        const {
            displayString,
            place: {
                geometry: { coordinates },
            },
        } = sugg;
        const displayName = displayString;
        const nameArray = displayName.split(', ');
        let label;
        if (nameArray.length >= 2) { // x, x; x, x, x; x, x, x, x; ...
            label = nameArray.slice(0, nameArray.length - 1).join(', ');
        } else {
            label = nameArray.join(', ');
        }
        const value = {
            ...sugg,
            lat: coordinates[1],
            lon: coordinates[0],
        };
        acc.push({ label, value });
        return acc;
    }, []);
}

const KEY = process.env.REACT_APP_MAPQUEST_KEY;
const BASE_URL = 'https://www.mapquestapi.com/search/v3/prediction';
const queryOptions = {
    key: KEY,
    limit: 5,
    collection: 'adminArea',
};

export function * fetchSearchSuggestions () {
    const searchText = yield select(selectors.zipSearch.selectSearchText);
    if (searchText.length < 2) {
        return null;
    }
    const options = {
        ...queryOptions,
        q: searchText,
    };
    const url = BASE_URL + '?' + qs.stringify(options);
    try {
        const { data } = yield cancellableFetch(url);
        const { request, results } = data;
        yield put(
            allActions.fullZipSearch.setSearchSuggestions(processSuggestions(results))
        );
    } catch (err) {
        yield put(allActions.fullZipSearch.setSearchSuggestions([]));
    } finally {
        if (yield cancelled()) {
        }
    }
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  isFetching: false,
  fetchingMessage: '',
  isError: false,
  errorMessage: '',
  searchRadius: 5,
  lastSearchedText: '',
  lastSearchedRadius: '',
  searchCoords: { lat: undefined, lon: undefined },
  lastSearchedCoords: { lat: undefined, lon: undefined },
  searchSuggestions: [],
};

const fullZipSearch = createSlice({
  name: 'fullZipSearch',
  initialState,
  reducers: {
    updateSearchCoords(state, action) {
      state.searchCoords = action.payload;
    },
    updateSearchText(state, action) {
      state.searchText = action.payload;
    },
    setLastSearchedText(state, action) {
      state.lastSearchedText = action.payload;
    },
    setSearchRadius(state, action) {
      state.searchRadius = action.payload;
    },
    setSearchSuggestions(state, action) {
      state.searchSuggestions = action.payload;
    },
    setFetchingMessage(state, action) {
      state.fetchingMessage = action.payload;
    },
    startZipSearch(state, action) {
      state.isFetching = true;
      state.fetchingMessage = 'Verifying Zipcode';
      state.errorMessage = '';
      state.isError = false;
      state.lastSearchedText = state.searchText;
      state.lastSearchedRadius = state.searchRadius;
      state.lastSearchedCoords = state.searchCoords;
    },
    startMdiSearch(state, action) {
      state.isFetching = true;
      state.fetchingMessage = 'Verifying Zipcode';
      state.errorMessage = '';
      state.isError = false;
      state.lastSearchedText = state.searchText;
      state.lastSearchedRadius = state.searchRadius;
      state.lastSearchedCoords = state.searchCoords;
    },
    startTagSearch(state, action) {
      state.isFetching = true;
      state.fetchingMessage = 'Verifying Zipcode';
      state.errorMessage = '';
      state.isError = false;
      state.lastSearchedText = state.searchText;
      state.lastSearchedRadius = state.searchRadius;
      state.lastSearchedCoords = state.searchCoords;
      state.lastSearchedTags = action.payload.flags;
    },
    finishZipSearchSuccess(state, action) {
      state.lastSearchedText = state.searchText;
      // state.searchText = '';
      state.suggestions = [];
      state.isFetching = false;
      state.fetchingMessage = '';
      state.errorMessage = '';
      state.isError = false;
    },
    finishZipSearchError(state, action) {
      state.isFetching = false;
      state.isError = true;
      state.fetchingMessage = '';
      state.errorMessage = action.payload;
      state.lastSearchedText = '';
    },
  },
});

export const {
  updateSearchText,
  setSearchRadius,
  startZipSearch,
  finishZipSearchSuccess,
  finishZipSearchError,
  setFetchingMessage,
  updateSearchCoords,
  setSearchSuggestions,
  setLastSearchedText,
  startMdiSearch,
  startTagSearch,
} = fullZipSearch.actions;

export default fullZipSearch.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ncua: true,
  fdic: true,
  mcu: true,
  mdi: true,
  licu: true,
  cdfi_fdic: true,
  cdfi_ncua: true,
  communityBank: true,
  other_fdic: true,
  other_ncua: true,
};

const institutionFiltersSlice = createSlice({
  name: 'institutionFilters',
  initialState,
  reducers: {
    setFlags(state, action) {
      const newFilters = { ...state, ...action.payload };
      return newFilters;
    },
    toggleFlag(state, action) {
      const { flag } = action.payload;
      state[flag] = !state[flag];
    },
    resetFilters(state, action) {
      return initialState;
    },
  },
});

export const {
  toggleFlag,
  resetFilters,
  setFlags,
} = institutionFiltersSlice.actions;

export default institutionFiltersSlice.reducer;

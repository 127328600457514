import React from 'react'
import { Box, Layer, Button } from 'grommet'
import { FormClose } from 'grommet-icons'
import { useCompact } from 'app/hooks'
import { AppBarLink } from 'components/molecules'
import { RenderMenuLinks } from 'components/cells'
import { Logo } from 'components/atoms'
import { links } from 'app/content/menu_links'
import PropTypes from 'prop-types'

export const SideMenu = ({ toggleMenu }) => {
  const isMobile = useCompact(['xsmall'])
  return (
    <Layer
      responsive={isMobile}
      position='right'
      onClickOutside={toggleMenu}
      full={'vertical'}
      background={'background-back'}
    >
      <Button id={'#toggle-menu'} plain onClick={toggleMenu}>
        <Box
          pad={'small'}
          fill={'horizontal'}
          align={'end'}
          background={'background-back'}
        >
          <FormClose size={'large'} color={'accent-1'} />
        </Box>
      </Button>
      <RenderMenuLinks
        links={links}
        direction={'column'}
        width={{ min: '300px' }}
        background={'background-back'}
        gap={'medium'}
        fill
        align={'center'}
        justify={'start'}
        pad={'medium'}
      />
      <Box
        background={'background-back'}
        justify={'center'}
        align={'center'}
        margin={{ top: 'auto' }}
      >
        <Logo height={300} width={250} />
      </Box>
    </Layer>
  )
}

SideMenu.propTypes = {
  toggleMenu: PropTypes.func.isRequired
}

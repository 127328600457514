import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Layer,
  Form,
  FormField,
  TextInput,
  Button,
  TextArea,
  Text,
  Select
} from 'grommet'
import { selectors, allActions } from 'reduxConfig'
import { useSelector, useDispatch } from 'react-redux'
import { Spinning } from 'grommet-controls'
import { useToggle } from 'react-use'

// this is now the custom flag tab.

export const EditFlagForm = ({
  allFlags,
  visible,
  flagToEdit,
  updateFlagToEdit,
  onDelete,
  submit
}) => {
  const editFlagFields = [
    {
      name: 'name',
      label: 'Flag Name',
      help: 'Lower Case Only',
      disabled: true
    },
    {
      name: 'shortLabel',
      help: 'Will show in the flag list',
      label: 'Short Label'
    },
    {
      name: 'fullLabel',
      help: 'Will show when flag is hovered',
      label: 'Full Label'
    },
    {
      name: 'description',
      label: 'Flag Description'
    }
  ]

  return (
    <React.Fragment>
      {visible && (
        <Form
          onSubmit={submit}
          value={flagToEdit}
          onChange={({ target: { value, name } }) => {
            updateFlagToEdit({ field: name, value })
          }}
        >
          {editFlagFields.map(fProps => {
            return (
              <FormField key={fProps.name} {...fProps}>
                <TextInput {...fProps} value={flagToEdit[fProps.name]} />
              </FormField>
            )
          })}
          <Box direction={'row'} gap={'small'} pad={'small'}>
            {(flagToEdit?.custom ?? false) && (
              <Button
                onClick={() => onDelete({ flagName: flagToEdit.name })}
                label={'Delete Flag'}
                color={'warning'}
              />
            )}
            <Button type={'submit'} label={'Update Flag'} primary />
          </Box>
        </Form>
      )}
    </React.Fragment>
  )
}

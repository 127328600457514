import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, CheckBox, Text } from 'grommet'
import { allActions } from 'reduxConfig'

export const RenderFilter = ({
  name,
  label,
  selector,
  isClickableSelector
}) => {
  const dispatch = useDispatch()
  const filterActive = useSelector(selector)
  const filterClickable = useSelector(isClickableSelector)

  const toggleFilter = () => {
    dispatch(allActions.institutionFilters.toggleFlag({ flag: name }))
  }

  return (
    <Box flex basis={'1/2'} key={name} direction={'row'} justify={'between'}>
      <Text size={'small'}>{label}</Text>
      <CheckBox
        disabled={!filterClickable}
        onChange={toggleFilter}
        checked={filterActive}
      />
    </Box>
  )
}

import {
  // GeoJsonLayer,
  PolygonLayer,
  ScatterplotLayer,
  // PointCloudLayer,
  TextLayer,
  LineLayer,
  IconLayer,
} from '@deck.gl/layers';
import circle from '@turf/circle';

import { Location } from 'grommet-icons';

const wrap = (s, w) =>
  s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');

export const generateDeckLayers = ({
  branchData,
  viewState,
  handleBankClick,
  searchRadius,
  searchCenter,
  selectedRssd,
}) => {
  // const verticalLineLayer = new LineLayer({
  //   id: 'line-layer',
  //   data: branchData,
  //   visible: viewState.pitch !== 0,
  //   getWidth: 1,
  //   getSourcePosition: d => [
  //     ...d.geometry.coordinates,
  //     d.properties.score * 100
  //   ],
  //   getTargetPosition: d => [...d.geometry.coordinates, 0],
  //   getColor: [0, 0, 0]
  // })

  // const branchCircleLayer = new ScatterplotLayer({
  //   id: 'geojson',
  //   data: branchData,
  //   pickable: true,
  //   filled: true,
  //   stroked: true,
  //   getLineColor: d =>
  //     d.properties.rssd === selectedRssd ? [0, 0, 0] : [255, 255, 255],
  //   lineWidthMinPixels: 2,
  //   getFillColor: d => {
  //     const x = d.properties.score
  //     const red = 255 * 2 * (1 - x)
  //     const green = 255 * 2 * x
  //     return [red, green, 0]
  //   },
  //   getPosition: d => [
  //     ...d.geometry.coordinates,
  //     d.properties.rssd === selectedRssd ? 100 : d.properties.score * 100
  //   ],
  //   getRadius: d =>
  //     selectedRssd === d.properties.rssd ? 100 : d.properties.score * 40,
  //   radiusMinPixels: 5,
  //   radiusMaxPixels: 50,
  //   onClick: ({ object, x, y }) => {
  //     handleBankClick(object.properties.rssd)
  //   },
  //   autoHighlight: true,
  //   highlightColor: [0, 0, 200],
  //   updateTriggers: {
  //     getRadius: [selectedRssd],
  //     getPosition: [selectedRssd],
  //     getFillColor: [selectedRssd],
  //     getLineColor: [selectedRssd]
  //   }
  // })

  const branchLabelLayer = new TextLayer({
    id: 'text-layer',
    data: branchData,
    pickable: true,
    billboard: true,
    visible: viewState.zoom > 14,
    backgroundColor: [100, 100, 44],
    getPosition: (d) => [...d.geometry.coordinates, d.properties.score / 100],
    getText: (d) => wrap(d.properties.bankName, 20),
    getColor: (d) =>
      d.properties.rssd === selectedRssd ? [0, 0, 0, 255] : [0, 0, 0, 255],
    getSize: (d) => (d.properties.rssd === selectedRssd ? 36 : 30),
    getAngle: 0,
    getTextAnchor: 'bottom',
    getAlignmentBaseline: 'bottom',
    fontSettings: {
      sdf: true,
    },
    fontFamily: 'Lato',
    width: 10,
    autoHighlight: true,
    highlightColor: [0, 100, 100],
    onClick: ({ object, x, y }) => {
      handleBankClick(object.properties.rssd);
    },
    // getPixelOffset: [0, 5],
    updateTriggers: {
      getColor: [selectedRssd],
      getPosition: [selectedRssd],
      getSize: [selectedRssd],
    },
  });

  const branchIconLayer = new IconLayer({
    id: 'branchLocations',
    data: branchData,
    pickable: true,
    sizeScale: 20,
    sizeUnits: 'meters',
    autoHighlight: true,
    alphaCutoff: 0.6,
    getHighlightColor: (d) => {
      const x = d.properties.score;
      const red = 255 * 2 * (1 - x);
      const green = 255 * 2 * x;
      return [red, green, 0];
    },
    getPosition: (d) => [
      ...d.geometry.coordinates,
      d.properties.rssd === selectedRssd
        ? d.properties.score / 50
        : d.properties.score / 25,
    ],
    getIcon: (d) => {
      const x = d.properties.score;
      return {
        url: '/003-plant.png',
        anchorX: 33,
        anchorY: 66,
        height: 100,
        width: 100,
        mask: true,
      };
    },
    getSize: (d) => 10 * d.properties.score,
    getColor: (d) => {
      const x = d.properties.score;
      const red = 255 * 2 * (1 - x);
      const green = 255 * 2 * x;
      return d.properties.rssd === selectedRssd
        ? [155, 89, 182]
        : [red / 1.3, green / 1.3, 0];
    },
    sizeMinPixels: 60,
    onClick: ({ object, x, y }) => {
      handleBankClick(object.properties.rssd);
    },
    updateTriggers: {
      getColor: [selectedRssd],
      getPosition: [selectedRssd],
      getSize: [selectedRssd],
      getIcon: [selectedRssd],
    },
  });

  const allLayers = [
    branchIconLayer,
    // verticalLineLayer,
    // branchCircleLayer,
    branchLabelLayer,
  ];

  if (!!searchRadius && searchCenter.length === 2) {
    const circlePoly = circle(searchCenter, searchRadius * 1.02, {
      steps: 64,
      units: 'miles',
    });
    const searchCircleLayer = new PolygonLayer({
      id: 'searchCircle',
      data: [circlePoly],
      filled: false,
      stroked: true,
      getLineColor: [0, 0, 0],
      lineWidthUnits: 'pixels',
      getPolygon: (d) => d.geometry.coordinates,
      getLineWidth: 3,
    });
    allLayers.push(searchCircleLayer);
  }

  return allLayers;
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Layer,
  Form,
  FormField,
  TextInput,
  Button,
  TextArea,
  Text,
  Select,
  CheckBox,
} from 'grommet';
import { selectors, allActions } from 'reduxConfig';
import { useSelector, useDispatch } from 'react-redux';
import { Spinning } from 'grommet-controls';
import { useToggle } from 'react-use';
import isEqual from 'lodash/isEqual';

// this is now the custom flag tab.

const customHeaderFields = [
  {
    name: 'bigText',
    label: 'Big Text',
  },
  {
    name: 'bigTextColor',
    label: 'Big Text Color',
  },
  {
    name: 'middleText',
    label: 'Middle Text',
  },
  {
    name: 'middleTextColor',
    label: 'Middle Text Color',
  },
  {
    name: 'backgroundColor',
    label: 'Background Color',
  },
  {
    name: 'buttonUrl_1',
    label: 'Button URL 1',
  },
  {
    name: 'buttonText_1',
    label: 'Button Text 1',
  },
  {
    name: 'buttonTextColor_1',
    label: 'Button Text Color 1',
  },
  {
    name: 'buttonColor_1',
    label: 'Button Color 1',
  },

  {
    name: 'buttonUrl_2',
    label: 'Button URL 2',
  },
  {
    name: 'buttonText_2',
    label: 'Button Text 2',
  },
  {
    name: 'buttonTextColor_2',
    label: 'Button Text Color 2',
  },
  {
    name: 'buttonColor_2',
    label: 'Button Color 2',
  },
];

export const CustomHeaderForm = ({ customHeader }) => {
  const dispatch = useDispatch();

  const [editedHeader, setEditedHeader] = React.useState(customHeader);

  const updateValue = ({ target: { value, name } }) => {
    const newValue = { ...editedHeader, [name]: value };
    setEditedHeader(newValue);
  };

  const updateBoolean = ({ target: { value, name } }) => () => {
    const newValue = { ...editedHeader, [name]: Boolean(value) };
    setEditedHeader(newValue);
  };

  const updateHeader = () => {
    const { _id, ...newData } = editedHeader;
    dispatch(allActions.customHeader.updateCustomHeader(newData));
  };

  // console.debug(editedHeader);

  return (
    <React.Fragment>
      <Form onSubmit={updateHeader} value={editedHeader}>
        {customHeaderFields.map((fProps) => {
          return (
            <FormField key={fProps.name} {...fProps}>
              <TextInput
                onChange={updateValue}
                {...fProps}
                value={editedHeader[fProps.name]}
              />
            </FormField>
          );
        })}
        <FormField name={'buttonActive_1'} label={'Is Button 1 Active'}>
          <Box pad={'small'}>
            <CheckBox
              name={'buttonActive_1'}
              // value={editedHeader.remoteUrl}
              checked={editedHeader?.buttonActive_1 ?? false}
              onChange={updateBoolean({
                target: {
                  name: 'buttonActive_1',
                  value: !editedHeader?.buttonActive_1,
                },
              })}
            />
          </Box>
        </FormField>
        <FormField name={'buttonActive_2'} label={'Is Button 2 Active?'}>
          <Box pad={'small'}>
            <CheckBox
              name={'buttonActive_2'}
              // value={editedHeader.remoteUrl}
              checked={editedHeader?.buttonActive_2 ?? false}
              onChange={updateBoolean({
                target: {
                  name: 'buttonActive_2',
                  value: !editedHeader?.buttonActive_2,
                },
              })}
            />
          </Box>
        </FormField>
        <FormField name={'remoteUrl_1'} label={'Is Button 1 A Remote URL?'}>
          <Box pad={'small'}>
            <CheckBox
              name={'remoteUrl_1'}
              checked={editedHeader?.remoteUrl_1 ?? false}
              onChange={updateBoolean({
                target: {
                  name: 'remoteUrl_1',
                  value: !editedHeader?.remoteUrl_1,
                },
              })}
            />
          </Box>
        </FormField>
        <FormField name={'remoteUrl_2'} label={'Is Button 2 A Remote URL?'}>
          <Box pad={'small'}>
            <CheckBox
              name={'remoteUrl_2'}
              checked={editedHeader?.remoteUrl_2 ?? false}
              onChange={updateBoolean({
                target: {
                  name: 'remoteUrl_2',
                  value: !editedHeader?.remoteUrl_2,
                },
              })}
            />
          </Box>
        </FormField>
        <Box direction={'row'} gap={'small'} pad={'small'}>
          <Button type={'submit'} label={'Update Custom Header'} primary />
        </Box>
      </Form>
    </React.Fragment>
  );
};

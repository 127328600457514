import React from 'react'
import { Box } from 'grommet'
import { AppBarLink } from 'components/molecules'
import PropTypes from 'prop-types'

export const RenderMenuLinks = ({ links, direction, ...rest }) => {
  const inAppBar = direction === 'row'
  return (
    <Box direction={direction} {...rest}>
      {links.map(({ name, label, sideLabel, path, useAnchor }) => {
        return (
          <AppBarLink
            key={name}
            name={name}
            label={label}
            sideLabel={sideLabel}
            path={path}
            useAnchor={useAnchor}
            inAppBar={inAppBar}
          />
        )
      })}
    </Box>
  )
}

RenderMenuLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sideLabel: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      useAnchor: PropTypes.bool.isRequired
    })
  ).isRequired,
  direction: PropTypes.oneOf(['row', 'column'])
}

RenderMenuLinks.defaultProps = {
  links: [],
  direction: 'row'
}

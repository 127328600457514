import React from 'react';
import { Layer, Box, Text, Button, Stack } from 'grommet';
import { SplashSearch } from 'app/grommetComponents';
import { Close } from 'grommet-icons';

const SearchModal = ({ hide, startSearch }) => {
  return (
    <Box fill justify={'center'} align={'center'}>
      <Stack fill anchor={'top-right'}>
        <Box
          fill
          justify={'center'}
          align={'center'}
          pad={{ top: 'large', left: 'small', right: 'small', bottom: 'small' }}
        >
          <Text textAlign={'center'} pad={'small'}>
            Please select a location to view local institutions
          </Text>
          <SplashSearch onPress={startSearch} />
        </Box>
        <Button icon={<Close />} onClick={hide} />
      </Stack>
    </Box>
  );
};

export default SearchModal;

import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

const CenterBox = ({ ...boxProps }) => (
  <Box
    gap={'small'}
    background={'light-1'}
    justify={'center'}
    align={'center'}
    flex={false}
    {...boxProps}
  />
)

CenterBox.propTypes = {}
CenterBox.defaultProps = {}

export default CenterBox

import React from 'react'
import { Box, Text, Heading, Button } from 'grommet'
import { Spinning } from 'grommet-controls'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'app/hooks'
import { useEffectOnce } from 'react-use'
import { allActions } from 'reduxConfig'
import { BankDetail } from './BankDetail'
import { selectors } from 'reduxConfig'

export const BankDetailComponent = ({
  rssd = undefined,
  bankName = undefined,
  closeButton,
  compact = false
}) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const isFetching = useSelector(selectors.bankDetails.selectIsFetching)
  const isError = useSelector(selectors.bankDetails.selectIsError)
  const isLoaded = useSelector(selectors.bankDetails.selectIsLoaded)
  const errorMessage = useSelector(selectors.bankDetails.selectErrorMessage)
  const bankDetails = useSelector(selectors.bankDetails.selectBankDetails)

  const bankRssd = rssd || (router?.query?.bankRssd ?? false)
  const retry = () =>
    dispatch(allActions.bankDetail.startGetBankDetail(+bankRssd))

  useEffectOnce(() => {
    if (!bankRssd) {
      router.replace('/search/zip')
    } else {
      dispatch(allActions.bankDetail.startGetBankDetail(+bankRssd))
    }
  }, [router, dispatch])

  const RenderFetching = () => (
    <Box fill as={'section'}>
      {(bankName || rssd) && (
        <Box
          fill={'horizontal'}
          align={'center'}
          justify={'center'}
          pad={'small'}
          flex={false}
          as={'header'}
        >
          <Heading level={3} size={'small'} color={'#fff'} margin={'none'}>
            {bankName || rssd}
          </Heading>
          {!!closeButton && <Box flex={false}>{closeButton}</Box>}
        </Box>
      )}
      <Box fill justify={'center'} align={'center'}>
        <Text textAlign={'center'} size={'large'} color={'white'}>
          Finding details{!bankName ? ` for ${bankName || bankRssd}` : ''}
        </Text>
        <Box margin={'large'}>
          <Spinning kind={'folding-cube'} size={'xlarge'} color={'white'} />
        </Box>
      </Box>
    </Box>
  )

  const RenderError = () => (
    <Box fill>
      {(bankName || rssd) && (
        <Box
          fill={'horizontal'}
          align={'center'}
          justify={'center'}
          pad={'small'}
          flex={false}
        >
          <Heading level={3} size={'small'} color={'#fff'} margin={'none'}>
            {bankName || rssd}
          </Heading>

          {!!closeButton && <Box flex={false}>{closeButton}</Box>}
        </Box>
      )}
      <Box
        fill
        gap={'small'}
        justify={'center'}
        align={'center'}
        pad={'medium'}
      >
        <Text textAlign={'center'} size={'large'} color={'white'}>
          Error retrieving details: {JSON.stringify(errorMessage)}
        </Text>
        <Button
          label={<Text color={'white'}>retry</Text>}
          onClick={retry}
          color={'white'}
        />
      </Box>
    </Box>
  )

  return (
    <Box flex overflow={'auto'} fill background={'background-back'}>
      {isFetching && <RenderFetching />}
      {isError && <RenderError />}
      {isLoaded && !!bankDetails && (
        <BankDetail closeButton={closeButton} compact={compact} />
      )}
    </Box>
  )
}

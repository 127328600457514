import React from 'react'
import { Box, Text } from 'grommet'
import { Spinning } from 'grommet-controls'
import { useSelector } from 'react-redux'
import { useRouter } from 'app/hooks'
import { selectors } from 'reduxConfig'

export const ZipSearchFetching = () => {
  const router = useRouter()
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius)
  const searchText = useSelector(selectors.zipSearch.selectSearchText)
  const { lat, lon } = router.query
  const coords = `[${lat}, ${lon}]`
  return (
    <Box pad={'large'} fill justify={'center'} align={'center'}>
      <Text textAlign={'center'} weight={'bold'} size={'large'} color={'brand'}>
        Finding better banking options within {searchRadius} miles of{' '}
        {searchText || coords}
      </Text>
      <Box margin={'large'}>
        <Spinning kind={'pulse'} size={'xlarge'} color={'accent-1'} />
      </Box>
    </Box>
  )
}

import React from 'react'
import { Box, Text, Heading, Anchor, DataTable } from 'grommet'
import { useSelector } from 'react-redux'
import { useTitle } from 'react-use'
import round from 'lodash/round'
import startsWith from 'lodash/startsWith'
import { CenterBox } from 'components/atoms'
import { TagBar } from 'components/molecules'
import { ScoreMeter } from 'app/grommetComponents'
import { selectors } from 'reduxConfig'
import ReactMarkdown from 'react-markdown'
import { bank_research_warning } from 'app/content/bank_details'

const formatBankWebsite = site => {
  if (!site || site === '') return 'Not Provided'
  if (startsWith(site, 'https')) return site
  if (startsWith(site, 'http')) return site.replace('http', 'https')
  return 'https://' + site
}

const Addresses = ({ details }) => {
  const website = formatBankWebsite(details.website)
  return (
    <Box justify={'start'} pad={'small'} gap={'small'} flex={false}>
      <Box flex={false}>
        <Text weight={'bold'}>Web Address</Text>
        {website !== 'Not Provided' ? (
          <Anchor rel='noopener noreferrer' href={website}>
            <Text truncate>{website}</Text>
          </Anchor>
        ) : (
          <Text truncate>{website}</Text>
        )}
      </Box>
      <Box flex={false}>
        <Text weight={'bold'}>HQ Address</Text>
        <Text>{details.streetAddress}</Text>
        <Text>
          {details.city}, {details.state} {details.zip}
        </Text>
      </Box>
    </Box>
  )
}

const dataCols = [
  {
    property: 'label',
    header: <Text size={'small'}>Scores</Text>,
    render: datum => <Text size={'small'}>{datum.label}</Text>
  },
  {
    property: 'weight',
    header: <Text size={'small'}>Weight</Text>,
    render: datum => (
      <Text size={'small'}>{round(datum.weight * 100, 2)}%</Text>
    ),
    align: 'center'
  },
  {
    property: 'rawScore',
    header: <Text size={'small'}>Raw Score</Text>,
    render: datum => (
      <Text size={'small'}>{round(datum.rawScore * 100, 2)}%</Text>
    ),
    align: 'center'
  },
  {
    property: 'weightedScore',
    header: <Text size={'small'}>Weighted Score</Text>,
    render: datum => (
      <Text size={'small'}>{round(datum.weightedScore * 100, 2)}%</Text>
    ),
    align: 'center'
  }
]

const BorderedCenterBox = props => (
  <CenterBox
    border={{ side: 'vertical', color: 'accent-1', size: 'medium' }}
    {...props}
  />
)

const formatCurrency = valueInBillions => {
  if (valueInBillions < 0.001) {
    return `${round(valueInBillions * 1000000000, 2).toLocaleString()} `
  }
  if (valueInBillions < 1) {
    return `${round(valueInBillions * 1000, 3)} million`
  }
  if (valueInBillions >= 1000) {
    return `${round(valueInBillions / 1000, 3)} trillion`
  }
  return `${round(valueInBillions, 3)} billion`
}

export const BankDetail = ({ closeButton = undefined, compact = false }) => {
  const bank = useSelector(selectors.bankDetails.selectBankDetails)

  useTitle(bank.name)

  const bankDollarStats = [
    {
      label: 'Total Assets',
      value: formatCurrency(bank.coreStatistics.totalAssetsBillions)
    },
    {
      label: 'Total Loans',
      value: formatCurrency(bank.coreStatistics.totalLoans / 1000000)
    },
    {
      label: 'Total Deposits',
      value: formatCurrency(bank.coreStatistics.totalDeposits / 1000000)
    }
  ]

  return (
    <Box fill pad={'small'}>
      <Box flex={false}>
        <Heading
          alignSelf={'center'}
          margin={'none'}
          color={'white'}
          textAlign={'center'}
          size={'small'}
          level={3}
        >
          {bank.name}
        </Heading>
        <TagBar
          flagArray={bank?.flagArray ?? []}
          showFull={false}
          align={'center'}
          justify={'center'}
          alignSelf={'center'}
        />
        <Text color={'light-3'} size={'small'} textAlign={'center'}>
          Member {bank.regulator.toUpperCase()} | RSSDID: {bank.rssd}
        </Text>
      </Box>
      <Box overflow={'auto'} gap={'small'}>
        <Box flex={false} direction={'row-responsive'} gap={'small'}>
          <BorderedCenterBox
            flex={'grow'}
            align={'start'}
            gridArea={'addresses'}
            round={'xsmall'}
          >
            <Addresses details={bank.details} />
          </BorderedCenterBox>

          <BorderedCenterBox
            pad={'small'}
            round={'xsmall'}
            flex={'shrink'}
            width={{ min: 'small' }}
          >
            <ScoreMeter bank={bank} />
          </BorderedCenterBox>
        </Box>
        <Box
          flex={false}
          justify={'center'}
          wrap={true}
          direction={'row-responsive'}
        >
          {bankDollarStats.map(({ label, value }) => {
            return (
              <BorderedCenterBox
                margin={'xsmall'}
                key={label}
                pad={'small'}
                round={'xsmall'}
              >
                <Text>
                  {label}: ${value}
                </Text>
              </BorderedCenterBox>
            )
          })}
        </Box>
        <BorderedCenterBox round={'xsmall'}>
          <DataTable
            // size={''}
            gridArea={'dataTable'}
            columns={dataCols}
            data={bank.scoresArray}
            height={'auto'}
          />
        </BorderedCenterBox>
        {(bank?.clientDetails?.notes ?? '').length > 0 && (
          <>
            <Text textAlign={'center'} color={'white'} size={'small'}>
              Our Notes
            </Text>
            <BorderedCenterBox
              fill={'horizontal'}
              background={'light-2'}
              round={'xsmall'}
              pad={'small'}
              justify={'start'}
              align={'start'}
            >
              <ReactMarkdown source={bank?.clientDetails?.notes ?? ''} />
            </BorderedCenterBox>
          </>
        )}

        <BorderedCenterBox
          fill={'horizontal'}
          background={'light-2'}
          round={'xsmall'}
          pad={'small'}
          as={'aside'}
        >
          <Text textAlign={'center'} size={'xsmall'}>
            {bank_research_warning}
          </Text>
        </BorderedCenterBox>
      </Box>
      {!!closeButton && (
        <Box margin={{ top: 'small' }} flex={false}>
          {closeButton}
        </Box>
      )}
    </Box>
  )
}

import { createSelector } from 'reselect';
// intersection maintains the order of the first array
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import round from 'lodash/round';
import * as stateDataSelectors from 'reduxConfig/cleanSelectors/stateData.selectors';
import * as filterSelectors from 'reduxConfig/cleanSelectors/institutionFilters.selectors';

// IDs for all flag filters

const getActiveIds = (active, ids) => (active ? ids : []);

export const selectMcuIds = createSelector(
  filterSelectors.selectMcuActive,
  stateDataSelectors.selectIds_mcu,
  getActiveIds
);

export const selectMdiIds = createSelector(
  filterSelectors.selectMdiActive,
  stateDataSelectors.selectIds_mdi,
  getActiveIds
);

export const selectLicuIds = createSelector(
  filterSelectors.selectLicuActive,
  stateDataSelectors.selectIds_licu,
  getActiveIds
);

export const selectFdicCdfiIds = createSelector(
  filterSelectors.selectFdicCdfiActive,
  stateDataSelectors.selectIds_cdfi,
  stateDataSelectors.selectIds_fdic,
  (active, cdfi_ids, fdic_ids) =>
    active ? intersection(fdic_ids, cdfi_ids) : []
);

export const selectNcuaCdfiIds = createSelector(
  filterSelectors.selectNcuaCdfiActive,
  stateDataSelectors.selectIds_cdfi,
  stateDataSelectors.selectIds_ncua,
  (active, cdfi_ids, ncua_ids) =>
    active ? intersection(ncua_ids, cdfi_ids) : []
);

export const selectCommunityBankIds = createSelector(
  filterSelectors.selectCommunityBankActive,
  stateDataSelectors.selectIds_communityBank,
  getActiveIds
);

// Ids for "other" flags

export const selectOtherFdicIds = createSelector(
  stateDataSelectors.selectIds_fdic,
  stateDataSelectors.selectIds_communityBank,
  stateDataSelectors.selectIds_mdi,
  selectFdicCdfiIds,
  filterSelectors.selectOtherFdicActive,
  (fdic, cb, mdi, cdfi_fdic, active) =>
    active ? difference(fdic, [...cb, ...mdi, ...cdfi_fdic]) : []
);

export const selectOtherNcuaIds = createSelector(
  stateDataSelectors.selectIds_ncua,
  stateDataSelectors.selectIds_mcu,
  stateDataSelectors.selectIds_licu,
  selectNcuaCdfiIds,
  filterSelectors.selectOtherNcuaActive,
  (ncua, mcu, licu, cdfi_ncua, active) =>
    active ? difference(ncua, [...mcu, ...licu, ...cdfi_ncua]) : []
);

// IDs for regulators

export const selectFdicIds = createSelector(
  filterSelectors.selectFdicActive,
  stateDataSelectors.selectIds_fdic,
  (fdicActive, fdicIds) => (fdicActive ? fdicIds : [])
);

export const selectNcuaIds = createSelector(
  filterSelectors.selectNcuaActive,
  stateDataSelectors.selectIds_ncua,
  (ncuaActive, ncuaIds) => (ncuaActive ? ncuaIds : [])
);

// Selecting visible institutions by regulator

export const selectFilteredFdicInstitutions = createSelector(
  selectFdicIds,
  selectOtherFdicIds,
  selectFdicCdfiIds,
  selectCommunityBankIds,
  selectMdiIds,
  (all, other, cdfi, cb, mdi) => {
    return intersection(all, uniq([...other, ...cdfi, ...cb, ...mdi]));
  }
);

export const selectFilteredNcuaInstitutions = createSelector(
  selectNcuaIds,
  selectOtherNcuaIds,
  selectNcuaCdfiIds,
  selectMcuIds,
  selectLicuIds,
  (all, other, cdfi, mcu, licu) => {
    return intersection(all, uniq([...other, ...cdfi, ...mcu, ...licu]));
  }
);

export const selectAllFilteredInstitutions = createSelector(
  selectFilteredFdicInstitutions,
  selectFilteredNcuaInstitutions,
  (fdic, ncua) => [...fdic, ...ncua]
);

export const selectOrderedVisibleInstitutionIds = createSelector(
  stateDataSelectors.selectOrderedBankIds,
  selectAllFilteredInstitutions,
  (orderedIds, filteredIds) => intersection(orderedIds, filteredIds)
);

export const selectMaxFilteredCount = createSelector(
  selectOrderedVisibleInstitutionIds,
  (ids) => ids.length
);

export const selectIdsToShowInFilteredResults = createSelector(
  selectOrderedVisibleInstitutionIds,
  stateDataSelectors.selectTopBankCount,
  (ids, count) => ids.slice(0, count)
);

export const selectFilteredRssdCount = createSelector(
  selectIdsToShowInFilteredResults,
  (ids) => ids.length
);

export const selectSearchResultsBankData = createSelector(
  selectIdsToShowInFilteredResults,
  stateDataSelectors.selectAllBanks,
  (ids, banks) => ids.map((id) => banks[id])
);

export const selectAllSearchResults = createSelector(
  selectAllFilteredInstitutions,
  stateDataSelectors.selectAllBanks,
  (ids, banks) => {
    return ids.map((id) => banks[id]);
  }
);

export const selectSearchResultsBankDataByMinDistance = createSelector(
  selectAllSearchResults,
  (banks) => {
    return sortBy(banks, (b) => {
      const branchDistance = Object.values(b.localBranches).map((branch) =>
        round(branch?.properties?.distToZip ?? 0, 2)
      );
      const closestBranchDistance = Math.min(...branchDistance);
      return closestBranchDistance;
    });
  }
);

export const selectMapBranchIds = createSelector(
  selectSearchResultsBankData,
  (banks) => {
    return flatten(banks.map((b) => b?.localBranchIds ?? []));
  }
);

export const selectMapBranchData = createSelector(
  selectMapBranchIds,
  stateDataSelectors.selectAllBranches,
  (ids, branches) => {
    return ids.reduce((acc, id) => {
      if (!!branches[id]) {
        acc.push(branches[id]);
      }
      return acc;
    }, []);
  }
);

import React from 'react';
import { Box, Text, Anchor } from 'grommet';
import { Facebook, Twitter } from 'grommet-icons';
import { Row } from 'components/atoms';

const Footer = () => {
  return (
    <Box
      tag="footer"
      background={'background-back'}
      pad={'small'}
      elevation={'medium'}
      flex={false}
    >
      <Box direction={'row'} justify={'between'} fill>
        <Box>
          <Box direction={'row'} gap={'small'} justify={'start'}>
            <Anchor
              size={'xsmall'}
              rel="noopener noreferrer"
              href={'https://www.betterbankingoptions.com/privacy-policy'}
              label={'Privacy Policy'}
            />
            <Anchor
              size={'xsmall'}
              rel="noopener noreferrer"
              href={'https://www.betterbankingoptions.com/terms-conditions'}
              label={'Terms & Conditions'}
            />
          </Box>
        </Box>
        <Row gap={'small'} justify={'center'} align={'center'}>
          <Anchor
            size={'xsmall'}
            rel="noopener noreferrer"
            href={'https://www.facebook.com/betterbankingoptions'}
          >
            <Facebook color={'#fff'} size={'20px'} />
          </Anchor>
          <Anchor
            size={'xsmall'}
            rel="noopener noreferrer"
            href={'https://twitter.com/BB_Options'}
          >
            <Twitter color={'#fff'} size={'20px'} />
          </Anchor>
        </Row>
        <Text size={'xsmall'} color={'accent-1'}>
          Copyright {new Date().getFullYear()} Just Money Advisors
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: undefined,
    user: undefined,
    token: undefined,
    accessToken: undefined,
    tokenType: undefined,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        startLogin (state, action) {
            state.loading = true;
        },
        loginSuccess (state, action) {
            const { accessToken, tokenType, user } = action.payload;
            state.accessToken = accessToken;
            state.tokenType = tokenType;
            state.user = user;
            state.loading = false;
            state.error = undefined;
        },
        setToken (state, action) {
            state.token = action.payload;
        },
        loginFailure (state, action) {
            state.loading = false;
            state.error = action.payload;
            state.accessToken = undefined;
            state.tokenType = undefined;
            state.user = undefined;
        },
        logout (state, action) {
            state.loading = false;
            state.error = undefined;
            state.accessToken = undefined;
            state.tokenType = undefined;
            state.user = undefined;
        },
    },
});

export const {
    startLogin,
    loginSuccess,
    loginFailure,
    logout,
    setToken,
} = authSlice.actions;

export default authSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drop, Text } from 'grommet';
import { Tag } from 'grommet-controls';

export const FlagTag = ({ label, size = 'xsmall' }) => {
  return (
    <Tag
      round={'small'}
      label={label}
      size={size}
      color={'background-back'}
      border={{ size: 'xsmall', color: 'background-back' }}
      background={'light-3'}
      pad={{ horizontal: 'small', vertical: 'xxsmall' }}
    />
  );
};

FlagTag.propTypes = {
  label: PropTypes.string.isRequired,
};

export const BankFlagTag = ({ description, short, full, showFull, size }) => {
  const [over, setOver] = React.useState(false);
  const tagRef = React.useRef(null);
  const tagLabel = showFull ? full : short;
  return (
    <Box justify={'center'} align={'center'} margin={{ vertical: 'xsmall' }}>
      <Box
        id={'tag-container'}
        ref={tagRef}
        onClick={() => setOver(!over)}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <FlagTag label={tagLabel} size={size} />
      </Box>

      {over && tagRef.current && (
        <Drop
          plain
          target={tagRef.current}
          align={{ bottom: 'top' }}
          style={{ zIndex: 20 }}
        >
          <Box
            margin={'xsmall'}
            pad={{ vertical: 'xsmall', horizontal: 'small' }}
            round
            width={{ max: 'medium' }}
            background={'light-1'}
            overflow={'hidden'}
            border={{ size: 'xsmall', color: 'background-back' }}
            gap={'xsmall'}
          >
            {!showFull && (
              <Text weight={'bold'} size={'small'} color={''} truncate>
                {full}
              </Text>
            )}
            {!!description && <Text size={'xsmall'}>{description}</Text>}
          </Box>
        </Drop>
      )}
    </Box>
  );
};

BankFlagTag.propTypes = {
  short: PropTypes.string.isRequired,
  full: PropTypes.string.isRequired,
  showFull: PropTypes.bool.isRequired,
};

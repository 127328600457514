import React from 'react';
import { Button, Drop, Box, Text } from 'grommet';
import { useGeolocation } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { allActions } from 'reduxConfig';
import {
    MapLocation,
    Location,
    LocationPin,
    Alert,
    Target,
    Vulnerability,
    StatusCritical,
} from 'grommet-icons';
import { Spinning } from 'grommet-controls';
import { useToggle } from 'react-use';
import round from 'lodash/round';
import appConfig from '../../../../config';

export const SearchGeoLocationButton = () => {
    const dispatch = useDispatch();
    const buttonRef = React.useRef(null);
    const [isHovered, setIsHovered] = React.useState(false);

    const { error, latitude, longitude, loading } = useGeolocation({
        maximumAge: 10 * 60 * 1000,
        timeout: appConfig.timeout,
        enableHighAccuracy: false,
    });

    const showHovered = () => setIsHovered(true);
    const hideHovered = () => setIsHovered(false);

    const handleClick = () => {
        dispatch(
            allActions.fullZipSearch.updateSearchCoords({
                lat: latitude,
                lon: longitude,
            })
        );
        dispatch(
            allActions.fullZipSearch.updateSearchText(
                `[${round(longitude, 4)}, ${round(latitude, 4)}]`
            )
        );
    };

    const errRef = React.useRef();

    if (error) return null;

    if (!latitude || !longitude) {
        return <Spinning />;
    }

    return (
        <Box flex={false}>
            <Button
                plain
                ref={buttonRef}
                onMouseEnter={showHovered}
                onMouseLeave={hideHovered}
                a11yTitle={'Use Geolocation'}
                icon={
                    <Vulnerability color={'brand'} margin={'none'} elevation={'medium'} />
                }
                onClick={handleClick}
                disabled={!!error}
            />
            {buttonRef.current && isHovered && (
                <Drop align={{ bottom: 'top' }} target={buttonRef.current} plain>
                    <Box
                        elevation={'small'}
                        margin={'xsmall'}
                        pad={'small'}
                        round={'xsmall'}
                        background={'background-back'}
                    >
                        <Text color={'#fff'} size={'xsmall'}>
                            {error ? 'Please enable geolocation' : 'Use Current Location'}
                        </Text>
                    </Box>
                </Drop>
            )}
        </Box>
    );
    // }

    // return (
    //   <Box flex={false}>
    //     <Button
    //       plain
    //       a11yTitle={'Finding Browser Location'}
    //       icon={<Spinning color={'brand'} />}
    //     />
    //   </Box>
    // );
};

import axios, { CancelToken } from 'axios'
import { CANCEL } from 'redux-saga'

let API_BASE_URL;
if (/(^|\.)dev\./.test(window.location.hostname)) {
    // DEV
    API_BASE_URL = 'https://6g8zkdz0pi.execute-api.us-east-1.amazonaws.com/dev/api/'; // TRAILING SLASH IS REQUIRED
} else {
    // PRODUCTION
    API_BASE_URL = 'https://g5rrmqohwc.execute-api.us-east-1.amazonaws.com/prod/api/'; // TRAILING SLASH IS REQUIRED
}

export function apiGet (url, data = {}, settings = {}) {
    const source = CancelToken.source()
    settings.cancelToken = source.token
    settings.baseURL = API_BASE_URL
    const urlObj = new URL(url, API_BASE_URL)
    for (const key in data) {
        if (Array.isArray(data[key])) {
            for (const value of data[key]) {
                urlObj.searchParams.append(key, value);
            }
        } else {
            urlObj.searchParams.append(key, data[key]);
        }
    }
    url = urlObj.toString();
    const request = axios.get(url, settings)
    request[CANCEL] = () => source.cancel()
    return request
}

export function apiPost (url, params, settings = {}) {
    const source = CancelToken.source()
    settings.cancelToken = source.token
    settings.baseURL = API_BASE_URL
    const request = axios.post(url, params, settings)
    request[CANCEL] = () => source.cancel()
    return request
}

export function cancellableFetch (
    url,
    params = {
        crossDomain: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    },
    settings = {}
) {
    const source = CancelToken.source()
    settings.cancelToken = source.token
    settings.baseURL = API_BASE_URL
    const request = axios.get(url, { params }, settings)
    request[CANCEL] = () => source.cancel()
    return request
}

import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
import 'mapbox-gl/dist/mapbox-gl.css'
import configureStore from 'reduxConfig'

const headId = document.getElementsByTagName('head')[0]
const fontLink = document.createElement('link')
fontLink.href =
  'https://fonts.googleapis.com/css?family=B612|Rubik|Lato|Recoleta&display=swap'
fontLink.rel = 'stylesheet'

headId.appendChild(fontLink)

let div = document.createElement('div')
div.setAttribute('id', 'app')
document.body.appendChild(div)

const mountNode = document.getElementById('app')
const store = configureStore()

const myRender = Component => {
  return ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Component />
      </Provider>
    </AppContainer>,
    mountNode
  )
}

myRender(App)

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default
    render(NextApp)
  })
}

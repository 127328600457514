import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: true,
  loadingMessage: '',
  error: false,
  errorMessage: '',
  successMessage: '',
  data: undefined,
  // {
  //   visible: false,
  //   bigText: '',
  //   middleText: '',
  //   buttonText: '',
  //   url: '',
  //   backgroundColor: '#000',
  //   buttonColor: '#fff',
  //   bigTextColor: '#fff',
  //   middleTextColor: '#fff',
  //   buttonTextColor: '#000',
  // },
};

const customHeaderSlice = createSlice({
  name: 'customHeader',
  initialState,
  reducers: {
    getCustomHeader(state, action) {
      // action used to kick off saga
      state.loading = true;
    },
    updateCustomHeader(state, action) {
      // action used to kick off saga
      // return state;
    },
    getCustomHeaderSuccess(state, action) {
      state.loading = false;
    },
    editCustomHeaderSuccess(state, action) {
      state.successMessage = 'Custom Header Edited Successfully.';
    },
    setCustomHeader(state, action) {
      state.data = action.payload;
    },
  },
});

export const {
  getCustomHeader,
  getCustomHeaderSuccess,
  updateCustomHeader,
  editCustomHeaderSuccess,
  setCustomHeader,
} = customHeaderSlice.actions;

export default customHeaderSlice.reducer;

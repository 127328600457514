import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'grommet'
import { useHistory } from 'react-router'

export const LinkButton = ({ to, onClick, ...rest }) => {
    const history = useHistory()
    const handleClick = event => {
        if (onClick) {
            onClick(event)
        }
        if (/^(https?:)?\/\//.test(to)) {
            window.location.assign(to);
        } else {
            history.push(to)
        }
    }

    return <Button {...rest} onClick={handleClick} />
}

LinkButton.propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.string.isRequired
}

import { selectors } from 'reduxConfig'

const fdic_all = {
  label: 'Show Banks',
  name: 'fdic',
  selector: selectors.institutionFilters.selectFdicActive,
  isClickableSelector: state => true // always clickable
}

const fdic_cdfis = {
  label: 'CDFI Banks',
  name: 'cdfi_fdic',
  selector: selectors.institutionFilters.selectFdicCdfiActive,
  isClickableSelector: selectors.institutionFilters.selectFdicActive // only clickable when banks are visible
}

const fdic_mdi = {
  label: 'Minority Banks',
  name: 'mdi',
  selector: selectors.institutionFilters.selectMdiActive,
  isClickableSelector: selectors.institutionFilters.selectFdicActive // only clickable when banks are visible
}

const fdic_cb = {
  label: 'Community Banks',
  name: 'communityBank',
  selector: selectors.institutionFilters.selectCommunityBankActive,
  isClickableSelector: selectors.institutionFilters.selectFdicActive // only clickable when banks are visible
}

const fdic_other = {
  label: 'All other Banks',
  name: 'other_fdic',
  selector: selectors.institutionFilters.selectOtherFdicActive,
  isClickableSelector: selectors.institutionFilters.selectFdicActive // only clickable when banks are visible
}

const ncua_all = {
  label: 'Show Credit Unions',
  name: 'ncua',
  selector: selectors.institutionFilters.selectNcuaActive,
  isClickableSelector: state => true // always clickable
}

const ncua_cdfis = {
  label: 'CDFI Credit Unions',
  name: 'cdfi_ncua',
  selector: selectors.institutionFilters.selectNcuaCdfiActive,
  isClickableSelector: selectors.institutionFilters.selectNcuaActive // only clickable when CUs are visible
}

const ncua_mcu = {
  label: 'Minority Credit Unions',
  name: 'mcu',
  selector: selectors.institutionFilters.selectMcuActive,
  isClickableSelector: selectors.institutionFilters.selectNcuaActive // only clickable when CUs are visible
}

const ncua_licu = {
  label: 'Low-Income Credit Unions',
  name: 'licu',
  selector: selectors.institutionFilters.selectLicuActive,
  isClickableSelector: selectors.institutionFilters.selectNcuaActive // only clickable when CUs are visible
}

const ncua_other = {
  label: 'All other Credit Unions',
  name: 'other_ncua',
  selector: selectors.institutionFilters.selectOtherNcuaActive,
  isClickableSelector: selectors.institutionFilters.selectNcuaActive // only clickable when CUs are visible
}

export const fdicFilters = [fdic_all, fdic_cdfis, fdic_mdi, fdic_cb, fdic_other]

export const ncuaFilters = [
  ncua_all,
  ncua_cdfis,
  ncua_mcu,
  ncua_licu,
  ncua_other
]

import { qualityLending } from './qualityLending.content'
import { qctPresence } from './qctPresence.content'
import { branchDensity } from './branchDensity.content'
import { totalAssets } from './totalAssets.content'

const branchDensityFull = {
  ...branchDensity,
  name: 'branchDensity',
  to: '/methodology/branch-density',
  iconSrc: '/branchDensity.svg',
  link: 'branch-density',
  weight: 0.1,
  color: 'branchDensity',
  weights: [
    { name: 'qualityLending', label: 'Quality Lending', weight: 0.6 },
    { name: 'qctPresence', label: 'Qualified Tract Presence', weight: 0.2 },
    { name: 'branchDensity', label: 'Branch Density', weight: 0.1 }
  ]
}

export const allMetrics = [
  qualityLending,
  qctPresence,
  branchDensityFull,
  totalAssets
]

export const links = allMetrics.map(({ label, name, to, iconSrc }) => ({
  label,
  name,
  to,
  iconSrc
}))

export const methodologyContent = allMetrics.reduce((acc, val) => {
  acc[val.name] = {
    ...val,
    otherLinks: links
  }
  return acc
}, {})

import { combineReducers } from '@reduxjs/toolkit';

import stateDataReducer, * as stateDataActions from './stateData.reducer';
import institutionFiltersReducer, * as institutionFiltersActions from './institutionFilters.reducer';
import bankDetailReducer, * as bankDetailActions from './bankDetail.reducer';
import themeReducer, * as themeActions from './theme.reducer';
import fullZipSearchReducer, * as fullZipSearchActions from './fullZipSearch.reducer';
import bankAdminReducer, * as bankAdminActions from './bankAdmin.reducer';
import authReducer, * as authActions from './auth.reducer';
import customHeaderReducer, * as customHeaderActions from './customHeader.reducer';

const rootReducer = combineReducers({
  // selectedState: selectedStateReducer,
  stateData: stateDataReducer,
  fullZipSearch: fullZipSearchReducer,
  bankDetail: bankDetailReducer,
  theme: themeReducer,
  institutionFilters: institutionFiltersReducer,
  bankAdmin: bankAdminReducer,
  auth: authReducer,
  customHeader: customHeaderReducer,
});

export const allActions = {
  theme: themeActions,
  stateData: stateDataActions,
  fullZipSearch: fullZipSearchActions,
  bankDetail: bankDetailActions,
  institutionFilters: institutionFiltersActions,
  bankAdmin: bankAdminActions,
  auth: authActions,
  customHeader: customHeaderActions,
};

export default rootReducer;

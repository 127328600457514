import React from 'react';
import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionPanel,
  Tabs,
  Tab,
  Button,
  Layer,
} from 'grommet';
import { useGeolocation } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { allActions, selectors } from 'reduxConfig';
import {
  ZipSearchResults,
  MdiSearchResults,
  SplashSearch,
} from 'app/grommetComponents';
import { Row } from 'components/atoms';
import { useCompact, useRouter } from 'app/hooks';
import { Spinning } from 'grommet-controls';
import queryString from 'query-string';
import { useZipSearch } from 'app/hooks/useZipSearch';
import SearchModal from './SearchModal';

export const LocalTab = ({ flagList = [], goToNationalTab, hide }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isCompact = useCompact(['xsmall', 'small']);

  const [searchModalVisible, setSearchModalVisibility] = React.useState(false);

  const showSearchModal = () => setSearchModalVisibility(true);
  const hideSearchModal = () => setSearchModalVisibility(false);

  const { lat, lon, searchRadius } = useZipSearch();

  const startSearch = React.useCallback(() => {
    dispatch(allActions.fullZipSearch.startTagSearch({ flags: flagList }));
  }, [flagList]);

  const executeSearch = () => {
    startSearch();
    hideSearchModal();
  };

  const navToMap = () => {
    const { str: flagString, obj: flagObject } = flagList.reduce(
      (acc, val) => {
        acc.str += `&${val}=true`;
        acc.obj[val] = true;
        return acc;
      },
      { str: '', obj: {} }
    );

    router.push({
      pathname: '/map',
      search: `?lat=${lat}&lon=${lon}&searchRadius=${searchRadius}${flagString}`,
      state: { lat, lon, searchRadius: searchRadius, flags: flagObject },
    });
  };

  React.useEffect(() => {
    if (lat && lon && searchRadius && !searchModalVisible) {
      startSearch();
    } else {
      showSearchModal();
    }
  }, [lat, lon, searchRadius, searchModalVisible]);

  return (
    <Box fill overflow={'auto'}>
      {searchModalVisible && (
        <Layer>
          <SearchModal hide={hideSearchModal} startSearch={executeSearch} />
        </Layer>
      )}
      <Box
        align={'center'}
        justify={'center'}
        pad={'xxsmall'}
        direction={'row'}
        gap={'small'}
        flex={false}
      >
        <Button label={'Update Search'} onClick={showSearchModal} />
        <Button label={'View on Map'} onClick={navToMap} />
      </Box>
      <MdiSearchResults sortByDistance />
    </Box>
  );
};

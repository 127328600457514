import { createSlice } from '@reduxjs/toolkit'
import { clamp } from 'ramda'

const initialState = {
  allInstitutions: {},
  allBanks: {},
  allBranches: {},
  orderedBankIds: [],
  bankIdsByFlag: {
    mdi: [],
    mcu: [],
    cdfi: [],
    licu: [],
    communityBank: []
  },
  bankIdsByRegulator: {
    fdic: [],
    ncua: []
  },
  fetching: false,
  error: false,
  visibleRssds: [],
  selectedRssd: '',
  searchCenter: [],
  topBankCount: 5
}

const stateDataSlice = createSlice({
  name: 'stateData',
  initialState,
  reducers: {
    setSelectedRssd (state, action) {
      state.selectedRssd =
        action.payload === state.selectedRssd ? '' : action.payload
    },
    incrementTopBankCount (state, action) {
      state.topBankCount = clamp(
        5,
        state.orderedBankIds.length,
        state.topBankCount + 5
      )
    },
    decrementTopBankCount (state, action) {
      state.topBankCount = clamp(
        5,
        state.orderedBankIds.length,
        state.topBankCount - 5
      )
    },
    resetStateData (state, action) {
      state = initialState
    },
    setSearchCenter (state, action) {
      state.searchCenter = action.payload
    },
    startFetching (state, action) {
      state.fetching = true
      state.error = false
    },
    endFetchSuccess (state, action) {
      state.fetching = false
      state.error = false
    },
    endFetchError (state, action) {
      state.fetching = false
      state.error = true
    },
    addStateData (state, action) {
      const {
        allBanks,
        allBranches,
        bankIdsByFlag,
        bankIdsByRegulator,
        orderedBankIds
      } = action.payload
      state.allBanks = allBanks
      state.allBranches = allBranches
      state.orderedBankIds = orderedBankIds
      state.allBranchIds = Object.keys(allBranches)
      state.fetching = false
      state.error = false
      state.bankIdsByFlag = bankIdsByFlag
      state.bankIdsByRegulator = bankIdsByRegulator
    },
    setVisibleRssds (state, action) {
      state.visibleRssds = action.payload
    }
  }
})

export const {
  startFetching,
  endFetchSuccess,
  endFetchError,
  addStateData,
  resetStateData,
  setVisibleRssds,
  setSelectedRssd,
  setSearchCenter,
  incrementTopBankCount,
  decrementTopBankCount
} = stateDataSlice.actions

export default stateDataSlice.reducer

import React from 'react';
import { Box } from 'grommet';
import {
  MethodologySection,
  BlogSection,
  DataUpdatedSection,
} from 'app/grommetComponents/HomePageSections';
import { ShortDescription } from 'app/grommetComponents';
import { HeaderBar, Row } from 'components/atoms';
import { useCompact } from 'app/hooks';
import { NewSplash } from './NewSplash';
import { BlmSplash } from './BlmSplash';

export const HomePage = () => {
  const isCompact = useCompact(['xsmall', 'small']);

  return (
    <Box data-component={'HomePage'} background={'light-2'} flex={'grow'}>
      <Box direction={isCompact ? 'column-reverse' : 'row'} flex={'grow'}>
        <NewSplash isCompact={isCompact} />
      </Box>
      <HeaderBar showIcons flex={false} text={'Banking Data Updated!'} />
      <DataUpdatedSection />
      <HeaderBar showIcons flex={false} text={'What makes a Better Bank'} />
      <MethodologySection />
      <HeaderBar showIcons text={'About Better Banking Options'} flex={false} />
      <Box flex={false}>
        <ShortDescription />
      </Box>
      <HeaderBar showIcons flex={false} text={'Our Blog'} />
      <BlogSection />
    </Box>
  );
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RangeInput, Box } from 'grommet';
import { allActions } from 'reduxConfig';
import { useUpdateEffect } from 'react-use';
import { selectors } from 'reduxConfig';

export const SearchRangeInput = (rangeProps) => {
  const dispatch = useDispatch();
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
  const [localRadius, setLocalRadius] = useState(searchRadius);

  //set local radius to search radius if it exists
  useEffect(() => {
    setLocalRadius(searchRadius);
  }, [searchRadius, setLocalRadius]);

  const updateRange = ({ target: { value } }) => {
    setLocalRadius(value);
  };

  // if local radius is updated, then dispatch the action to update central store
  // we are using a local radius to make sure that there is no lag in showing
  // the user the search radius in real time

  useUpdateEffect(() => {
    if (+localRadius !== +searchRadius) {
      dispatch(allActions.fullZipSearch.setSearchRadius(localRadius));
    }
  }, [dispatch, localRadius]);

  return (
    <RangeInput
      min={1}
      max={30}
      step={1}
      onChange={updateRange}
      value={localRadius}
      {...rangeProps}
    />
  );
};

import React from 'react'
import { Box, Heading, Text, Paragraph } from 'grommet'
import PropTypes from 'prop-types'
import { Divider, HeaderBar, LinkButton } from 'components/atoms'
import { WeightMeter } from './WeightMeter'
import { useCompact } from 'app/hooks'

export const RenderScoreSection = ({
  name,
  label,
  headerText,
  hookText,
  blurbText,
  definition,
  color,
  weight,
  inputs = [],
  equation = 'y=m*x + b',
  link,
  weights,
  renderIcon,
  iconSrc
}) => {
  const isCompact = useCompact(['xsmall', 'small'])
  return (
    <Box
      key={name}
      round={'small'}
      // fill={'horizontal'}
      width={{ max: isCompact ? '100%' : '325px' }}
      justify={'between'}
      background={'light-3'}
      elevation={'medium'}
      margin={'small'}
      border={{ size: '4px', color: name }}
    >
      <HeaderBar
        size={'medium'}
        level={1}
        background={name}
        border={{ side: 'bottom', size: '4px', color: name }}
        pad={'small'}
      >
        <Box
          align={'center'}
          fill={'horizontal'}
          direction={'row'}
          justify={'between'}
        >
          <Heading
            responsive={false}
            margin={'none'}
            level={4}
            size={'large'}
            color={'white'}
          >
            {hookText}
          </Heading>
          <img alt={`${label} icon`} src={iconSrc} height={40} width={40} />
        </Box>
      </HeaderBar>
      <Box fill={'horizontal'}>
        <Box pad={'small'}>
          <WeightMeter
            weights={weights}
            centerWeight={weight}
            weightName={name}
          />
          <Heading
            color={'background-back'}
            textAlign={'center'}
            level={3}
            size={'small'}
            margin={{ bottom: 'small' }}
          >
            {headerText}
          </Heading>
          <Divider
            height={'2px'}
            width={'90%'}
            alignSelf={'center'}
            color={name}
          />

          <Box pad={'small'} gap={'xsmall'} align={'center'}>
            <Paragraph>{blurbText}</Paragraph>
          </Box>
        </Box>
      </Box>
      <Box flex={'grow'} justify={'end'} align={'center'} pad={'small'}>
        <LinkButton
          to={'/methodology/' + link}
          color={name}
          label={
            <Box pad={'small'}>
              <Text size={'xsmall'}>LEARN MORE</Text>
            </Box>
          }
        ></LinkButton>
      </Box>
    </Box>
  )
}

RenderScoreSection.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grommet, TextInput, Text } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { allActions } from 'reduxConfig';
import { useUpdateEffect, useDebounce } from 'react-use';
import { selectors } from 'reduxConfig';
import { myCustomTheme } from 'theme';
import { useRouter } from 'app/hooks';

const processSuggestions = (results = []) => {
  return results.reduce((acc, sugg) => {
    const displayName = sugg.display_name;
    const nameArray = displayName.split(', ');
    const label = nameArray.slice(0, nameArray.length - 1).join(', ');

    acc.push({ label, value: sugg });
    return acc;
  }, []);
};

export const customTheme = deepMerge(myCustomTheme, {
  textInput: {
    extend: () => `
      font-family: Lato;
      font-size: 18px;
      text-align: center;
    `,
    suggestions: {
      extend: () => `
        max-width: 400px;
        li {
          text-wrap: wrap;
          border-bottom: 1px solid orange;
          text-align: center;
          padding: 4px;
          font-size: 14px;
          font-family: Lato;
        }
      `,
    },
  },
});

export const SearchInput = (textInputProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const searchText = useSelector(selectors.zipSearch.selectSearchText);
  const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
  const lastSearchedText = useSelector(
    selectors.zipSearch.selectLastSearchedText
  );
  const suggestions = useSelector(selectors.zipSearch.selectSearchSuggestions);

  const handleSelectSuggestion = (evt) => {
    const {
      suggestion: {
        label,
        value: { lat, lon },
      },
    } = evt;
    const coords = { lat, lon };
    dispatch(allActions.fullZipSearch.updateSearchText(label));
    dispatch(allActions.fullZipSearch.updateSearchCoords(coords));
    dispatch(allActions.fullZipSearch.startZipSearch());
    router.push({
      pathname: '/map',
      search: `?lat=${coords.lat}&lon=${coords.lon}&searchRadius=${searchRadius}`,
      state: { coords, searchRadius: searchRadius },
    });
  };

  const handleUpdate = ({ target: { value } }) => {
    dispatch(allActions.fullZipSearch.updateSearchText(value));
  };

  return (
    <Box fill={'horizontal'}>
      <Grommet theme={customTheme}>
        <TextInput
          type={'text'}
          value={searchText}
          onChange={handleUpdate}
          placeholder={lastSearchedText || 'Find your location'}
          dropAlign={{ top: 'bottom' }}
          onSelect={(suggestion) => {
            // console.info('SUGGESTION: ', suggestion);
          }}
          suggestions={suggestions}
        />
      </Grommet>
    </Box>
  );
};

const content = `Better Banking Options has always operated in a political realm, as banks are essential to modern economic function and our economy is inextricably tied to politics. Although we strive for our resources to be useful to people of all backgrounds and opinions, we are also a mission-driven entity with values many would consider to be “left-leaning.”...`

const title = `Disinvesting from war in the Middle East`

const publishDate = `April 12, 2024`

const author = `Sara Loving`

const link = `https://www.betterbankingoptions.com/single-post/disinvesting-from-war`

export const right_post = {
  content,
  title,
  publishDate,
  author,
  link
}

import React from 'react';
import { Box, Text } from 'grommet';
import { RenderFilter } from './RenderFilter';
import { fdicFilters, ncuaFilters } from './filters';
import { useRouter } from 'app/hooks';

const RenderFilters = ({ filters, label }) => {
  return (
    <Box pad={'small'} fill wrap gap={'xsmall'} direction={'column'}>
      <Box>
        <Text>{label}</Text>
      </Box>
      <Box direction={'column'}>{filters.map(RenderFilter)}</Box>
    </Box>
  );
};

export const InstitutionFilters = () => {
  return (
    <Box direction={'row'}>
      <RenderFilters filters={fdicFilters} label={'Bank Filters'} />
      <RenderFilters filters={ncuaFilters} label={'Credit Union Filters'} />
    </Box>
  );
};

import React from 'react';
import {
    Box,
    Drop,
    Heading,
    TextInput,
    RangeInput,
    Text,
    Button,
    Grommet,
} from 'grommet';
import { SplashSearch, customTheme } from 'app/grommetComponents';
import { useDispatch, useSelector } from 'react-redux';
import { allActions, selectors } from 'reduxConfig';
import { SearchButtonUI } from 'components/cells';
import { useGeolocation } from 'react-use';
import { Spinning } from 'grommet-controls';
import { Location, LocationPin, StatusCritical } from 'grommet-icons';
import round from 'lodash/round';
import { useRouter } from 'app/hooks';
import appConfig from '../../../config';

const HeaderText = () => {
    return (
        <Box align={'center'} justify={'center'} flex={'shrink'}>
            <Heading textAlign={'center'} level={1} size={'large'} color={'brand'}>
                Where you bank{' '}
                <Heading size={'large'} as={'span'} level={1} color={'cta'}>
                    MATTERS
                </Heading>
            </Heading>
        </Box>
    );
};

// some parameters used by SplashSearch2
export const NewSearchComponent = ({
    beforeSearch = () => {},
    flex,
    gap = 'medium',
    width = { max: 'medium' },
    align,
    direction,
    justify,
    onPress = () => {},
    title = 'Find Better Banks',
}) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const searchText = useSelector(selectors.zipSearch.selectSearchText);
    const searchRadius = useSelector(selectors.zipSearch.selectSearchRadius);
    const searchSuggestions = useSelector(
        selectors.zipSearch.selectSearchSuggestions
    );
    const lastSearchedText = useSelector(
        selectors.zipSearch.selectLastSearchedText
    );
    const searchCoords = useSelector(selectors.zipSearch.selectSearchCoords);
    const [localRadius, setLocalRadius] = React.useState(5);
    const [locationSelected, setLocationSelected] = React.useState(false);

    const { error, latitude, longitude, loading } = useGeolocation({
        maximumAge: 10 * 60 * 1000,
        timeout: appConfig.timeout,
        enableHighAccuracy: false,
    });

    const updateSearchText = (t) =>
          dispatch(allActions.fullZipSearch.updateSearchText(t));
    const updateSearchCoords = (coords) =>
          dispatch(allActions.fullZipSearch.updateSearchCoords(coords));
    const updateSearchRadius = (r) => {
        dispatch(allActions.fullZipSearch.setSearchRadius(r));
    };

    const onChangeSearchText = (event) => {
        setLocationSelected(false);
        event.preventDefault();
        const {
            target: { value },
        } = event;
        updateSearchText(value);
    };

    const updateRange = (event) => {
        event.preventDefault();
        const {
            target: { value },
        } = event;
        setLocalRadius(value);
    };

    React.useEffect(() => {
        if (localRadius !== searchRadius) {
            updateSearchRadius(localRadius);
        }
    }, [localRadius, searchRadius]);

    React.useEffect(() => {
        if (lastSearchedText) {
            setLocationSelected(true);
        }
    }, [lastSearchedText]);

    const handleSelectSuggestion = ({ suggestion }) => {
        const {
            label,
            value: { lat, lon },
        } = suggestion;

        if (label && lat && lon) {
            updateSearchText(label);
            updateSearchCoords({ lat, lon });
            setLocationSelected(true);
        }
    };

    const handleUseGeoCoords = () => {
        setLocationSelected(true);
        updateSearchCoords({ lat: latitude, lon: longitude });
        updateSearchText(
            `My Location (${round(latitude, 3)}, ${round(longitude, 3)})`
        );
    };

    const startZipSearch = () => {
        beforeSearch();
        if (searchCoords && locationSelected && searchRadius && searchRadius > 0) {
            dispatch(allActions.fullZipSearch.startZipSearch());
            router.push({
                pathname: '/map',
                search: `?lat=${searchCoords.lat}&lon=${searchCoords.lon}&searchRadius=${searchRadius}`,
                state: { ...searchCoords, searchRadius: searchRadius },
            });
        }
    };

    return (
        <Box flex={flex} margin={'small'} gap={gap} align={align} direction={direction} justify={justify} width={width}>
            <Text alignSelf={'center'} size={'small'}>
                {locationSelected
                 ? 'Set a radius & click find below'
                 : 'Select a location'}
            </Text>
            <Grommet theme={customTheme}>
                <TextInput
                    style={{ textAlign: 'center' }}
                    value={searchText}
                    onChange={onChangeSearchText}
                    suggestions={locationSelected ? [] : searchSuggestions}
                    onSelect={handleSelectSuggestion}
                    placeholder={lastSearchedText}
                />
            </Grommet>

            {!searchText && (
                <div style={{ alignSelf: 'center' }}>
                    <Button
                        style={{ borderWidth: 0, padding: 5 }}
                        onClick={handleUseGeoCoords}
                        reverse
                        label={
                            <Text size={'xsmall'}>
                                {error ? 'Geolocation is disabled' : 'Use My Location'}
                            </Text>
                        }
                        disabled={loading || error}
                        icon={
                            loading ? <Spinning /> : error ? <StatusCritical /> : <Location />
                        }
                    />
                </div>
            )}
            {locationSelected && (
                <>
                    <RangeInput
                        min={1}
                        max={30}
                        step={1}
                        onChange={updateRange}
                        value={localRadius}
                        color={'background-back'}
                        style={{ color: 'cta' }}
                    />
                    <Text
                        color={'background-back'}
                        weight={'bold'}
                        textAlign={'center'}
                        size={'xsmall'}
                    >
                        Search Radius: {localRadius} mile{localRadius !== 1 ? 's' : ''}
                    </Text>
                </>
            )}
            <SearchButtonUI
                disabled={!locationSelected}
                color={'accent-3'}
                title={title}
                onPress={onPress}
                onClick={startZipSearch}
            />
        </Box>
    );
};

export const NewSplash = ({ isCompact }) => {
    return (
        <Box
            data-component={'NewSplash'}
            pad={'medium'}
            gap={'medium'}
            flex={'grow'}
            direction={'column'}
            // justify={'between'}
            align={'center'}
        >
        <HeaderText />
        <NewSearchComponent />
        </Box>
    );
};

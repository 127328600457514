import React from 'react';
import { Box } from 'grommet';
import { useCompact } from 'app/hooks';
// import AppBar from './AppBar'
import Footer from './Footer';
import { useTitle } from 'react-use';
// import { AppBar } from 'app/grommetComponents'
import { AppBar } from 'components/organelles';
import styled from 'styled-components';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const AppLayoutScroll = ({ children }) => {
  const isCompressed = useCompact();

  return (
    <Box style={{ height: '100%', width: '100%' }}>
      <AppBar displayMenuButton={isCompressed} />
      <Box fill justify={'between'}>
        <Box fill flex={'grow'}>
          {children}
        </Box>
        <Box flex={false}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export const AppLayoutFixed = ({ children }) => {
  const isCompressed = useCompact();

  return (
    <Box style={{ height: '100%', width: '100%' }}>
      <AppBar displayMenuButton={isCompressed} />
      <Box flex={'grow'} overflow={'auto'}>
        {children}
      </Box>
      <Box flex={false}>
        <Footer />
      </Box>
    </Box>
  );
};

// map: {
//     path: '/map',
//     exact: false,
//     label: 'Better Banking Map',
//     showSearch: false,
//     stickyFooter: false,
//     component: MapPage,
//   },

export const withAppLayout = ({
  showSearch = true,
  stickyFooter = true,
  showFooter = true,
  label,
}) => (WrappedComponent) => () => {
  useTitle(label);
  return stickyFooter ? (
    <AppLayoutFixed showSearch={showSearch}>
      <WrappedComponent />
    </AppLayoutFixed>
  ) : (
    <AppLayoutScroll showSearch={showSearch}>
      <PageContainer>
        <WrappedComponent />
      </PageContainer>
    </AppLayoutScroll>
  );
};

import React from 'react'
import { Logo, LinkButton } from 'components/atoms'
import { Heading } from 'grommet'
import PropTypes from 'prop-types'

export const LogoButton = ({}) => {
    return (
        <LinkButton
            as={'button'}
            plain
            icon={<Logo />}
            label={
                <Heading
                    color={'light-1'}
                    level={3}
                    margin='none'
                    weight={'bold'}
                    truncate
                >
                    Better Banking Options
                </Heading>
            }
            to={'https://map.betterbankingoptions.com/'}
        />
    )
}

LogoButton.propTypes = {}

LogoButton.defaultProps = {}

import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

export const Divider = ({ width, height, color, ...rest }) => {
  return <Box width={width} height={height} background={color} {...rest} />
}

Divider.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string
}

Divider.defaultProps = {
  width: '100%',
  height: '2px',
  color: 'accent-1'
}

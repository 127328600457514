import React from 'react'
import { Box, Text, Anchor } from 'grommet'
import { homePageShortParagraph } from 'app/content/about_page'
import ReactMarkdown from 'react-markdown'

export const ShortDescription = () => {
  return (
    <Box pad={'medium'} width={{ max: 'large' }} alignSelf={'center'}>
      <Text>
        <ReactMarkdown source={homePageShortParagraph} />
      </Text>
      <Anchor
        alignSelf={'center'}
        plain
        color={'brand'}
        target={'_blank'}
        href={'https://www.betterbankingoptions.com/our-staff'}
        rel={'noopener noreferrer'}
      >
        Learn more about us and our mission!
      </Anchor>
    </Box>
  )
}

import React from 'react'
import { Box, Button } from 'grommet'
import { Header } from 'grommet-controls'
import { Menu } from 'grommet-icons'

import { links } from 'app/content/menu_links'
import { LogoButton } from 'components/molecules'
import { RenderMenuLinks } from 'components/cells'
import { SideMenu } from 'components/organelles'
import { useCompact } from 'app/hooks'
import { useToggle } from 'react-use'
import PropTypes from 'prop-types'

const staticHeaderProps = {
  border: {
    side: 'bottom',
    size: 'small',
    color: 'brand'
  },
  position: 'sticky',
  background: 'background-back',
  zIndex: 1,
  pad: 'medium',
  direction: 'row',
  fill: 'horizontal',
  justify: 'between',
  align: 'center',
  height: '60px'
}

export const RenderLinksOrButton = ({ depShowCompact }) => {
  const isCompact = useCompact(['xsmall', 'small'])
  const [menuVisible, toggleMenuVisible] = useToggle(false)
  const showButton = isCompact || depShowCompact

  return (
    <React.Fragment>
      {showButton ? (
        <Button
          icon={<Menu color={'accent-1'} />}
          onClick={toggleMenuVisible}
        />
      ) : (
        <RenderMenuLinks
          links={links}
          fill
          justify={'end'}
          gap={'medium'}
          pad={{ right: 'small' }}
          align={'center'}
        />
      )}
      {menuVisible && <SideMenu toggleMenu={toggleMenuVisible} />}
    </React.Fragment>
  )
}

RenderLinksOrButton.propTypes = {
  depShowCompact: PropTypes.bool
}

export const AppBar = ({}) => {
  return (
    <Header {...staticHeaderProps}>
      <LogoButton />
      <RenderLinksOrButton />
    </Header>
  )
}

AppBar.propTypes = {}
